import React from "react";
// Customizable Area Start
import { Box, Button, Card, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, Breadcrumbs,
  Typography } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { DeleteOutline, Edit, FilterList, SearchOutlined } from "@material-ui/icons";
import AppNavigation from '../../../components/src/AppNavigation.web';
import { I18n } from "../../../components/src/languageJson/i18n";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <div style={{display:'flex'}}>
        <AppNavigation />
        <div style={{flex:1, overflow: 'hidden'}}>
          <ThemeProvider theme={theme}>
            <div style={{backgroundColor:"#F1F5FC", padding:'50px 20px'}}>
            <DynamicBreadcrumbs
  breadcrumbItems={[I18n.t("home"), I18n.t("serviceManagement")]}
/>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom:"20px" }}>
                    <div style={{ display: "flex", justifyContent:"center", width: "80%", borderRadius: "0.5rem" }}>
                        <div className="btncontainer" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", width: "60%" }}>
                          
                      <InputField
                        type="search"
                        id="search"
                        data-test-id="searchinput"
                        onChange={this.handleSearchInput}
                        onKeyPress={(event: React.KeyboardEvent) => {
                          if (event.key === 'Enter') {
                            this.SearchService();
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchOutlined  style={{color:"#94A3B8"}}/>
                            </InputAdornment>
                          ),
                        
                        }}
                        placeholder="Search"
                        variant="outlined"
                        style={{ width: "100%",backgroundColor:"#FFFFFF", borderRadius:"25px 0 0 25px" }}
                      />
                          

                        </div>
                        <Button data-test-id="searchField" onClick={this.SearchService} variant="outlined" style={{ fontSize: '18px', fontWeight: 700, textTransform: "none",backgroundColor:"#527FE2",borderRadius:"0 25px 25px 0" }}> <FilterList style={{color:"ffffff"}}/></Button>
                    </div>
                </div>
                <CardBox >

                <div className="table" style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                    <Table style={{ width: "95%",height:"4rem" }} >

                      <TableHead>
                        <TableRow style={useStyles.tableRow}>
                          <TableCellHead style={{ borderRadius: "0 0 0 24px" }}>Service</TableCellHead>
                          <TableCellHead >Service Price</TableCellHead>
                          <TableCellHead  >Creation Date</TableCellHead>
                          <TableCellHead  >Update Date</TableCellHead>
                          <TableCellHead  ></TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.arrayHolder.map((row: any, index: any) => (
                          <TableRow style={useStyles.tableRow} key={index}>
                            <TableCellBody data-test-id={`name-${row.id}`} >{row.attributes.name}</TableCellBody>
                            <TableCellBody >{row.attributes.price}$</TableCellBody>
                            <TableCellBody >{this.toDateFromMoment(row.attributes.created_at)}</TableCellBody>
                            <TableCellBody >{this.toDateFromMoment(row.attributes.updated_at)}</TableCellBody>
                            <TableCellBody align="center"><Edit style={{ color: "#527FE2" }} /> <DeleteOutline data-test-id={`delete-${row.id}`} onClick={()=>this.DeleteService(row.id)} style={{ color: "#DC2626",cursor:"pointer" }} /></TableCellBody>
                          </TableRow>))}

                      </TableBody>
                    </Table>
                  
                </div>
                </CardBox>
                  <Box data-test-id="goToAddService" onClick={this.goToAddService} 
                  style={{display:"flex",justifyContent:"center",margin:"3rem"}}> 
                  <AddServiceBtn>Add New Service</AddServiceBtn>
                  </Box>
              
                
            </div>
          </ThemeProvider>
        </div>
      </div>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start



const useStyles = {
  tableRow: {
      margin: "5px 0 5px 0",


  }
}
const CardBox = styled(Card)({
   overflowY:"auto",height:"60vh",
   backgroundColor:"fffffff", border:"1 px solid #CBD5E1",
   width:"100%",margin:"auto"
   ,borderRadius:"1.5rem",
   "@media (max-width:560px)": {
   overflow:"scroll",
   display:"flex",
   marginTop:"20px"
  },
 
 })

const TableCellHead = styled(TableCell)({
 fontWeight:700,border:"none",backgroundColor:"#527FE2",color:"ffffff",
 fontFamily:"Poppins",fontSize:"1rem",padding:"20px 24px 20px 24px",height:"64px"

})

const TableCellBody = styled(TableCell)({
 border:"none","height": '44px',fontFamily:"Poppins",fontSize:"16px",padding:"20px 24px 20px 24px"
})
const InputField = styled(TextField)({
  '& .MuiInputBase-input::placeholder': {
    color: '#94A3B8', 
    opacity: 1,
  },
  "& .MuiInputBase-root": {
    height: "44px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0 8px", 
    height: "100%", 
    boxSizing: "border-box", 
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px", borderRadius: "25px 0 0 25px",
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform: "uppercase",
    color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },
 
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#6EE7B7",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#6EE7B7",
    },
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",marginRight: "0px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px)": {
    minWidth: "unset",
    maxWidth: "unset",
  },
});

const AddServiceBtn = styled(Button)({
  color: "white",
  borderRadius: "8px",
  backgroundColor: "#527FE2",
  textTransform: "none",
  fontFamily: "Poppins",fontWeight:700,
  fontSize: "16px",
  padding: "10px 16px 10px 16px",
  maxWidth: "306px",
  width:"306px",
  lineHeight:"24px",
  height: "44px",
  "&:hover": {
    color: "white", backgroundColor: "#527FE2",
  },
  "@media(max-width:600px)": {
    fontSize:"18px",padding: "10px 16px 10px 16px",
  },
  
});

// Customizable Area End
