import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Platform,
} from "react-native";

import { styled } from "@material-ui/styles";
import { Box, Typography, Card, Button, TextField, Grid, InputAdornment, IconButton, TextareaAutosize, Modal, FormControlLabel, Checkbox, FormGroup, MenuItem } from "@material-ui/core";
import { branch, card, vat, home, email, taxino, auth, authId,deleteimg, iban, time,branchCodeIcon, categories, location, ophours, attatchfile,addIcon,checked,unchecked,cancleRoundRed,checkedRound} from "./assets";
import { Autocomplete } from "@material-ui/lab";
import PhoneInput from "react-phone-number-input";


const BranchTYpe: Dropdown[] = [
  { label: "Multiple Branches", value: "2" },
  { label: "Individual Branch", value: "0" },  
];
// Customizable Area End

import UserProfileBasicController, {
  Props,
  Dropdown,
} from "./UserProfileBasicController.web";
import { Close } from "@material-ui/icons";

export default class UserProfileBasicBlock extends UserProfileBasicController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAddBranchBox() {
    if (this.state.branchSelection === "2") {
      return (
        <Box style={webStyle.branchBox} data-test-id="addfield" onClick={this.addBranch}>
          <Typography style={webStyle.addBranch}>
            Add Branch
          </Typography>
          <img src={addIcon} alt="addIcon" style={webStyle.addBranchIcon} />
        </Box>
      );
    }
    return null;
  }

  getErrorMessageNonFormik = (value: string) => {
    return (

      <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
      >
        {value}
      </Typography>

    );
  };  

  conditionalRenderObject(condition:any,trueStmt:any,falseStmt:any){
    return condition ? condition[trueStmt] : falseStmt
  }

  getOptionLabelFunction = (option: any) => {
  return option.attributes?.name || "Unknown Category";
};

  selectedCategoryName = this.state.selectedCategory?.attributes?.name;
  shouldShowPhoneNumberLabel = !this.state.phoneNumber;
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    // const { navigation } = this.props;
    return (
      //Required for all blocks
      <Box style={{ maxWidth: "100vw", minHeight: "1250px", background: "#CBD5E1" }}>
        <Box style={{ maxHeight: "318px", width: "100%", overflow: "hidden" }}>
          <img
          src={this.getCoverImageSrc()}
          alt="topBackImg" style={{height:"318px",width:"100%",overflow:"hidden",objectFit:"cover"}} 
          />
        </Box>
        <EditBtn onClick={this.handleFileCoverClick}>Edit Cover</EditBtn>
        <input
          type="file"
          data-test-id="TradingLicenseFile"
          ref={this.state.uploadCover}
          style={{ display: 'none' }}
          accept=".jpeg, .jpg, .png"
          onChange={this.handleFileCoverPhoto}
        />

        <Card style={webStyle.cardStle}>
          <Box style={webStyle.imageBOx}>
            <ImageProfile
            src={this.getUploadImageSrc()}
            alt="profile"  onClick={this.handleFileProfileick4}
            />
            <input
              type="file"
              data-test-id="TradingLicenseFile"
              ref={this.state.uplodaProfile}
              style={{ display: 'none' }}
              accept=".jpeg, .jpg, .png"
              onChange={this.handleFileChangeProfile4}
            />
            <Box >
              <ProfileName > SAMSUNG</ProfileName>
              <ProfileCatText >Retail & E-Commerce</ProfileCatText>
            </Box>
          </Box>


          <Box
            id="modal-title"
            mb="20px"
            mt="20px"
            fontFamily="Poppins"
            fontWeight="bolder"
          >
            <Typography style={webStyle.formHeading}>General Information</Typography>
          </Box>

          <form
            onSubmit={this.handleSubmit}
            noValidate autoComplete="off">

            <Grid container spacing={3}>
              <Grid item sm={6} xs={12} md={6}  >
                <InputField
                  data-test-id="NationalId"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  placeholder="National Id *"
                  value={this.state.profileData.attributes?.national_id}
                  onChange={(event) => {
                    const value = event.target.value;
                    const NospaceValue = value?.replace(/\s/g, '');
                    this.onValueChange("national_id", NospaceValue);
                  }}
                  InputProps={{

                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={card}
                          alt="user icon"
                          style={{
                            width: 20,
                            height: 16
                          }} />
                      </InputAdornment>
                    ),

                  }}
                />
                {this.nationalError("NationalId")}
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <InputField
                  data-test-id="VatRegNo"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  placeholder="Vat Registration Number *"
                  value={this.state.profileData.attributes?.vat_registration_number}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    const NospaceValue = value?.replace(/\s/g, '');
                    this.onValueChange("vat_registration_number", NospaceValue);
                  }}

                  InputProps={{

                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={vat}
                          alt="user icon"
                          style={{ width: 20, height: 18 }} />
                      </InputAdornment>
                    ),

                  }}
                />
                {this.vatRegNo("VatRegNo")}

              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <InputField
                  data-test-id="NationalAddress"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  placeholder="National Address *"
                  value={this.state.profileData.attributes?.address}
                  onChange={(event: any) => {
                    this.onValueChange(
                      "address",
                      event.target.value
                    );
                  }}
                  InputProps={{

                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={home}
                          alt="user icon"
                          style={{ width: 16, height: 17.5 }} />
                      </InputAdornment>
                    ),

                  }}
                />
                {this.nationalAddress("NationalAddress")}
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <InputField
                  data-test-id="email"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  placeholder="Support email (Option)"
                  value={this.state.profileData.attributes?.support_email}
                  onChange={(event: any) => {
                    this.onValueChange("support_email", event.target.value);
                  }}

                  InputProps={{

                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                           src={email}
                          alt="user icon"
                          style={{ width: 20, height: 16 }} />
                      </InputAdornment>
                    ),

                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  id="modal-title"
                  fontFamily="Poppins"
                  fontWeight="bolder"
                >
                  <Typography style={webStyle.formHeading}>Company Information</Typography>
                </Box>
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <InputField
                  data-test-id="BusinessName"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  placeholder="Business name *"
                  value={this.state.profileData.attributes?.business_name}

                  onChange={(event: any) => {
                    this.onValueChange(
                      "business_name",
                      event.target.value
                    );
                  }}
                  InputProps={{

                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={vat}
                          alt="user icon"
                          style={{ width: 20, height: 18 }} />
                      </InputAdornment>
                    ),

                  }}
                />
              {this.businessName("BusinessName")}

              </Grid>
              <Grid item sm={6} xs={12} md={6}>

                <InputField
                  data-test-id="TradingLicense"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  placeholder="Trading License *"
                  value={this.state.profileData.attributes?.trading_license_image?.name}

                  onClick={this.handleFileInputClick4}
                  InputProps={{
                    readOnly: true,

                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={card}
                          alt="License"
                          style={{ width: 20, height: 16 }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton >
                          <img
                            src={attatchfile}
                            alt="attachFile"
                            style={{ width: 11, height: 22 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  type="file"
                  data-test-id="TradingLicenseFile"
                  ref={this.state.fileInput4}
                  style={{ display: 'none' }}
                  accept=".pdf"
                  onChange={this.handleFileChange4}
                />

                {this.state.isFieldValueChanged.TradingLicense && this.tradingLicense("TradingLicense")}

              </Grid>

              <Grid item sm={6} xs={12} md={6}>
                <InputField
                  data-test-id="VATtaxidentifire"
                  fullWidth
                  variant="outlined"
                  onClick={this.handleFileInputClick3}
                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  placeholder="Vat Tax Identifier *"
                  value={this.state.profileData.attributes?.vat_tax_identifier_image?.name}
                  onChange={(event: any) => {
                    this.onValueChange("VATtaxidentifire", event.target.value);
                  }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={taxino}
                          alt="TaxIdentifier"
                          style={{ width: 14, height: 18 }} />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton

                        >
                          <img
                            src={attatchfile}
                            alt="attachFile"
                            style={{ width: 11, height: 22 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  type="file"
                  data-test-id="VATtaxidentifireFile"
                  ref={this.state.fileInput3}
                  style={{ display: 'none' }}
                  onChange={this.handleFileChange3}
                  accept=".pdf"
                />
                {this.state.isFieldValueChanged.VATtaxidentifire && this.vattaxidentifire("VATtaxidentifire")}

              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <InputField
                  data-test-id="authorizedCertificate"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  // error={this.state.errors.authorizedCertificate}
                  placeholder="Authorization Certificate *"
                  value={this.state.profileData.attributes?.authorization_certificate_image?.name}
                  onChange={(event: any) => {
                    this.onValueChange("authorizedCertificate", event.target.value);
                  }}
                  onClick={this.handleFileInputClick2}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={auth}
                          alt="authCertificate"
                          style={{ width: 24, height: 24 }} />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                        >
                          <img
                            src={attatchfile}
                            alt="attachFile"
                            style={{ width: 11, height: 22 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  type="file"
                  ref={this.state.fileInput2}
                  data-test-id="authorizedCertificateFile"
                  style={{ display: 'none' }}
                  onChange={this.handleFileChange2}
                  accept=".pdf"
                />
                {this.state.isFieldValueChanged.authorizedCertificate && this.authorizedCertificate("authorizedCertificate")}
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <InputField
                  data-test-id="authorizedIDcard"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  // error={this.state.errors.authorizedIDcard}
                  placeholder="Authorized Id Card *"
                  value={this.state.profileData.attributes?.authorized_id_card_image?.name}
                  onChange={(event: any) => {
                    this.onValueChange("authorizedIDcard", event.target.value);
                  }}
                  onClick={this.handleFileInputClick1}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={authId}
                          alt="authidcard"
                          style={{
                            width: 18,
                            height: 14
                          }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton

                        >
                          <img
                            src={attatchfile}
                            alt="attachFile"
                            style={{ width: 11, height: 22 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  type="file"
                  ref={this.state.fileInput1}
                  data-test-id="authorizedIDcardFile"
                  style={{ display: 'none' }}
                  onChange={this.handleFileChange1}
                  accept=".pdf"
                />
                {this.state.isFieldValueChanged.authorizedIDcard && this.authorizedIDcard("authorizedIDcard")}

              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <InputField
                  data-test-id="ibanCertificate"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  // error={this.state.errors.ibanCertificate}
                  placeholder="IBAN Certificate *"
                  value={this.state.profileData.attributes?.iban_certificate_image?.name}

                  onClick={this.handleFileInputClick}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={iban}
                          alt="ibanCertificate"
                          style={{ width: 22, height: 18 }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton

                        >
                          <img
                            src={attatchfile}
                            alt="attachFile"
                            style={{ width: 11, height: 22 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  type="file"
                  data-test-id="ibanCertificateFile"
                  ref={this.state.fileInput}
                  style={{ display: 'none' }}
                  onChange={this.handleFileChange}
                  accept=".pdf"
                />
              {this.state.isFieldValueChanged.ibanCertificate && this.ibanCertificate("ibanCertificate")}
              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                <InputField
                  data-test-id="OtherCertificate"
                  fullWidth
                  variant="outlined"

                  inputProps={{
                    style: {
                      height: "16px",
                    },
                  }}
                  placeholder="Add Other Document (Optional)"
                  value={this.state.profileData.attributes?.other_document_image?.name}

                  onClick={this.handleOtherFileInputClick}
                  InputProps={{
                    readOnly: true,

                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton

                          style={{ padding: "0px" }}>
                          <img
                            src={attatchfile}
                            alt="attachFile"
                            style={{ width: 11, height: 22 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  type="file"
                  data-test-id="OtherCertificateFile"
                  ref={this.state.otherFileInput}
                  style={{ display: 'none' }}
                  onChange={this.OtherhandleFileChange}
                  accept=".pdf"
                />

                <Autocomplete
                  style={{ marginTop: "20px" }}
                  data-test-id="industryCat"
                  options={
                    this.state.categoryData as {
                      id: string;
                      type: string;
                      attributes: {
                        id: number;
                        name: string;
                        arabic_name?: string;
                        image?: string;
                      };
                    }[]
                  }
                  getOptionLabel={(option) => this.getOptionLabelFunction(option)}
                  value={this.state.selectedCategory}
                  onChange={(event, newValue) => this.handleCategoryChange(newValue)}
                  renderInput={(params) => (
                    <InputField
                      {...params}
                      placeholder="Categories *"
                    variant="outlined"
                    value={ this.selectedCategoryName }
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={categories}
                              alt="category icon"
                              style={{ width: 19, height: 20, paddingLeft: "6px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                {this.industryCat("industryCat")}
              </Grid>
              

              <Grid item sm={6} xs={12} md={6}>
                <StyledTextarea minRows={4}
                  value={this.state.profileData.attributes?.business_description}
                  data-test-id="businessDescription"
                  onChange={(event: any) => {
                    this.onValueChange("businessDescription", event.target.value);
                  }}
                  placeholder="Business Description *" />
                {this.businessDescription("businessDescription")}


              </Grid>
              <Grid item sm={6} xs={12} md={6}>
                  <Autocomplete
                  
                    data-test-id="branchSelection"
                    options={BranchTYpe}
                    getOptionLabel={(option) => option.label || 'Unknown'}
                    onChange={(event, newValue) => this.handleBranchSelectionChange(newValue)}
                    value={
                      BranchTYpe.find(option => option.value === (this.conditionalRenderObject(this.state.branchSelection === 'individual_branch' , '0', '2')))
                    }
                   
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        placeholder="Branch Selection *"
                        variant="outlined"
                        value={BranchTYpe.find(option => option.value === (this.conditionalRenderObject(this.state.branchSelection === 'individual_branch' , '0', '2')))?.label}
                        error={this.state.errors.branchSelection}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img 
                                src={branch}
                                alt="user icon"
                                style={{ width: 18, height: 21}} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {this.branchSelection("branchSelection")}

              </Grid>
              {this.state.branchSelection ?
                <>
                  <Grid item xs={12}>
                    <Box
                      id="modal-title"
                      fontFamily="Poppins"
                      fontWeight="bolder"
                      style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                    >
                      <Typography style={webStyle.formHeading}>Branch Details</Typography>
                        {this.renderAddBranchBox()}
                    </Box>
                  </Grid>

                  <>
                    {this.state.profileData?.attributes?.branches?.map((branch: any, index: any) => (
                      <Box key={branch.id} mb={4} width={'100%'} padding={'12px'}>
                        <Box mb={2} style={{ display: "flex", justifyContent: "space-between" }}>
                          <strong style={{ fontFamily: "Poppins", fontSize: "18px" }}>Branch {index + 1}</strong>
                          {index > 0 ?
                            <Button
                              data-test-id={`delete-${index}`}
                              onClick={() => this.deleteBranch(index)}
                            >
                              <img
                                src={deleteimg}
                                alt="deleteimg"
                                style={{ height: "24px", width: "24px", cursor: "pointer" }} />
                            </Button>
                            :
                            null
                          }
                        </Box>
                        <Grid container spacing={3}>
                          <Grid item sm={6} xs={12} md={6}>
                            <InputField
                              data-test-id={`branchName-${index}`}
                              fullWidth
                              variant="outlined"
                              inputProps={{ style: { height: "16px" } }}
                              onChange={(event: any) => this.onValueChangeNew(index, "branch_name", event)}
                              placeholder="Branch Name *"
                              name={`branches[${index}].branchName`}
                              error={this.state.errors[`branches[${index}].branch_name`]}
                              value={this.state.profileData.attributes?.branches[index].branch_name}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={card}
                                      alt="Branch Name Icon" style={{ width: 20, height: 16 }} />
                                  </InputAdornment>
                                ),
                              }} />
                           
                              {this.branchNameError(`branches[${index}].branch_name`)}

                          </Grid>
                          <Grid item sm={6} xs={12} md={6}>
                            <InputField
                              data-test-id={`branchCode${index}`}
                              fullWidth
                              variant="outlined"
                              inputProps={{ style: { height: "16px" } }}
                              placeholder="Branch Code *"
                              error={this.state.errors[`branches[${index}].branch_code`]}
                              value={this.state.profileData.attributes?.branches[index]?.branch_code}
                              onChange={(event: any) => this.onValueChangeNew(index, "branch_code", event.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={branchCodeIcon}
                                      alt="Branch Code Icon" style={{ width: 20, height: 16 }} />
                                  </InputAdornment>
                                ),
                              }} />
                  
                              {this.branchCodeError(`branches[${index}].branch_code`)}
                          </Grid>

                          <Grid item sm={6} xs={12} md={6}>
                            <InputField
                              data-test-id={`location_link-${index}`}
                              fullWidth
                              variant="outlined"
                              inputProps={{ style: { height: "16px" } }}
                              placeholder="Branch Location *"
                              value={this.state.profileData.attributes?.branches[index]?.location_link}
                              error={this.state.errors[`branches[${index}].location_link`]}
                              onChange={(event: any) => this.onValueChangeNew(index, "location_link", event.target.value)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={location}
                                      alt="Location Icon" style={{ width: 16, height: 17 }} />
                                  </InputAdornment>
                                ),
                              }} />
                              {this.branchLocationLinkError(`branches[${index}].location_link`)}
                          </Grid>

                          <Grid item sm={6} xs={12} md={6}>
                            <div style={{ position: "relative" }}>
                              {this.shouldShowPhoneNumberLabel && (
                                <label
                                  style={{
                                    position: "absolute",
                                    top: 18,
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    lineHeight: "24px",
                                    color: "#94A3B8",
                                  }}
                                  htmlFor=""
                                >
                                </label>
                              )}
                              <PhoneStyle
                                placeholder="Branch Contact Number *"
                                data-test-id={`branchContactNo-${index}`}
                                value={this.state.profileData.attributes?.branches[index]?.mobile_number}
                                phoneNumber
                                error={this.state.errors[`branches[${index}].mobile_number`]}
                                countries={[]}
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="AE"
                                onChange={(value: any) => this.onValueChangeNew(index, "mobile_number", value)} />

                                {this.branchContanctNoError(`branches[${index}].mobile_number`)}
                            </div>

                          </Grid>

                          <Grid item sm={6} xs={12} md={6}>
                            <InputField
                              data-test-id={`branchOperatingHours-${index}`}
                              fullWidth
                              variant="outlined"
                              inputProps={{ style: { height: "16px" } }}
                              placeholder="Branch Operating Hours *"
                              value={this.state.profileData.attributes.branches[index]?.business_hours}
                              onClick={() => this.handleShowTime(index)}
                              error={this.state.errors[`branches[${index}].business_hours`]}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src={ophours}
                                      alt="Operating Hours Icon" style={{ width: 18, height: 21 }}
                                      onClick={() => this.handleShowTime(index)} />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="start" data-test-id="modalOperatingHours">
                                    <img
                                      src={time}
                                      alt="Time Icon"
                                      style={{ width: 20, height: 20, cursor: "pointer" }} />
                                  </InputAdornment>
                                ),
                              }} />
                              {this.branchOperatingHoursError(`branches[${index}].business_hours`)}
                            <Modal
                              open={this.state.showMOdaltime}
                              onClose={this.handleHideshowTime}
                              aria-labelledby="parent-modal-title"
                              aria-describedby="parent-modal-description"
                            >
                              <Box style={Modalstyles.modal}>
                                <Box style={Modalstyles.subModalDiv}>
                                  <Box style={Modalstyles.topboxstyle}>
                                    <HeadingText>Branch Operating Hours</HeadingText>
                                    <Close style={{ paddingRight: "1rem" }} onClick={this.handleHideshowTime} />
                                  </Box>

                                  <Box style={Modalstyles.topboxstyle}>
                                    <SubHeadingText>Weekly Working Days</SubHeadingText>
                                  </Box>

                                  <Box style={Modalstyles.topboxstyle2}>
                                    <FormGroup
                                      style={{
                                        display: "flex", flexDirection: "row",
                                        padding: "16px 40px 16px 40px", maxWidth: "100%", borderBottom: "1px solid #E2E8F0", justifyContent: "space-between"
                                      }}
                                    >
                                      {["Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map((day) => (
                                        <FormControlLabel
                                          key={day}
                                          control={<Checkbox
                                            icon={<img src={unchecked} style={{ width: "20px", height: "20px", paddingBottom: "6px" }} />}
                                            checkedIcon={<img src={checked} style={{ width: "20px", height: "20px", paddingBottom: "6px" }} />}
                                            style={{ color: "#527FE2" }}
                                            name={day}
                                            onChange={(event) => this.handleCheckboxChange(index, day, event.target.checked)} />}
                                          label={day.charAt(0).toUpperCase() + day.slice(1)}
                                          data-test-id={day} />
                                      ))}
                                    </FormGroup>
                                  </Box>

                                  <Box style={Modalstyles.dailyWorkingHrs}>
                                    <SubHeadingText>Daily Working Hours</SubHeadingText>


                                    <BoxTimeTop>

                                      <BoxTime>
                                        <HeadingTime style={{ marginRight: "1rem" }}>Start Time</HeadingTime>
                                        <TimeRange123
                                          value={branch?.startTime?.hours}
                                          data-test-id={`starthours-${index}`}
                                          onChange={(eve: any) => this.handleHourChange(eve, index)}
                                          placeholder="00"
                                          variant="outlined"
                                          type="number"
                                          style={{
                                            width: 60, fontSize: "20px"
                                          }} />
                                        <TimeRange123
                                          value={branch?.startTime?.minutes}
                                          data-test-id={`startminutes-${index}`}
                                          onChange={(eve: any) => this.handleMinuteChange(eve, index)}
                                          placeholder="00"
                                          variant="outlined"
                                          type="number"
                                          inputProps={{ min: 0, max: 59 }}
                                          style={{
                                            width: 60, fontSize: "20px"
                                          }} />
                                        <TimeRange123
                                          select
                                          // value={this.state.profileData.attributes.branches[index]?.business_hours}
                                          onChange={(eve: any) => this.handlePeriodChange(eve, index)}
                                          defaultValue={"AM"}
                                          data-test-id={`startperiod-${index}`}
                                        >
                                          <MenuItem value="AM">AM</MenuItem>
                                          <MenuItem value="PM">PM</MenuItem>
                                        </TimeRange123>
                                      </BoxTime>

                                      <BoxTime>
                                        <HeadingTime style={{ marginRight: "26px" }}>End Time</HeadingTime>
                                        <TimeRange123
                                          value={branch?.endTime?.hours}
                                          data-test-id={`endhours-${index}`}
                                          onChange={(eve: any) => this.handleHourChangeEnd(eve, index)}
                                          placeholder="00"
                                          // value={this.state.profileData.attributes.branches[index]?.business_hours?.spilt(" ")[1].spilt(":")[0]}
                                          variant="outlined"
                                          type="number"
                                          inputProps={{ min: 0, max: 24 }}
                                          style={{
                                            width: 60, fontSize: "20px"
                                          }} />
                                        <TimeRange123
                                          value={branch?.endTime?.minutes}
                                          data-test-id={`endminutes-${index}`}
                                          onChange={(eve: any) => this.handleMinuteChangeEnd(eve, index)}
                                          placeholder="00"
                                          variant="outlined"
                                          type="number"
                                          inputProps={{ min: 0, max: 59 }}
                                          style={{
                                            width: 60, fontSize: "20px"
                                          }} />
                                        <TimeRange123
                                          select
                                          value={branch?.endTime?.period}
                                          defaultValue={"PM"}
                                          onChange={(eve: any) => this.handlePeriodChangeEnd(eve, index)}
                                          data-test-id={`endperiod-${index}`}
                                        >
                                          <MenuItem value="AM">AM</MenuItem>
                                          <MenuItem value="PM">PM</MenuItem>
                                        </TimeRange123>
                                      </BoxTime>
                                    </BoxTimeTop>
                                  </Box>

                                  <Box style={{
                                    display: "flex", justifyContent: "flex-end",
                                    padding: "20px 0px", width: "100%", borderTop: "1px solid #CBD5E1"
                                  }}>
                                    <AcceptBtn data-test-id="modalsubmit" onClick={this.onHandleSubmitTime}>Submit</AcceptBtn>
                                  </Box>
                                </Box>
                              </Box>
                            </Modal>


                          </Grid>


                        </Grid>
                      </Box>
                    ))}
                  </>
                </>
                : null}

             

            </Grid>

            <SaveBtnBox>
              <SubmitButn 
                data-test-id="formDatasumit"
                type="submit" fullWidth
                >
                Save Changes
              </SubmitButn>
            </SaveBtnBox>

          </form>


        </Card>
        <Modal
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          style={stylesDialog.modal}
        >
          <div style={stylesDialog.modalContent}>
            <div style={stylesDialog.buttonContainer1}>
              {this.state.successError ? (
                <>

                  <div style={{ height: "63.75px", width: "63.75px" }}>
                    <img src={cancleRoundRed} alt="Cancle" style={stylesDialog.imgCancle} />
                  </div>
                  <Typography style={stylesDialog.errorTitle}>
                  Required fields are missing. Please complete all mandatory fields to continue.
                  </Typography>
                </>
              ) : (
                <>
                  <div style={{ height: "70px", width: "70px" }}>
                    <img src={checkedRound} alt="Cancle" style={stylesDialog.imgCancle} />
                  </div>
                  <Typography style={stylesDialog.errorTitle}>
                    Profile Updated Successfully
                  </Typography>
                </>
              )}
            </div>
            <div style={stylesDialog.buttonContainer}>
              {this.state.successError ? (
                <>
                  <Button variant="contained" style={stylesDialog.tryAgain} onClick={this.handleDialogClose}>
                    Try Again
                  </Button>
                  <Button variant="outlined" style={stylesDialog.cancle} onClick={this.handleDialogClose}>
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="outlined" style={stylesDialog.cancle} 
                    onClick={this.navigateEducationalUserProfile}
                  >
                    Back
                  </Button>
                </>
              )}
            </div>
          </div>
        </Modal>
      </Box>
     
    );
    // Customizable Area End
  }

 
  
  // Customizable Area Start
  // Customizable Area End
  
}
// Customizable Area Start
const stylesDialog = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '8px',
    outline: 'none',
    maxWidth: '500px',
    textAlign: 'center' as 'center',
    display:"flex",
    flexDirection:"column" as 'column',
    justifyContent:"center" as 'center',
  },
  imgCancle: {
    height:"100%",
    width:"100%",
  },
  buttonContainer: {
    marginTop: '40px',
    display: 'flex',
    flexDirection:"column" as 'column',
    gap:"10px",
  },
  buttonContainer1: {
    display: 'flex',
    flexDirection:"column" as 'column',
    gap:"30px",
    alignItems:"center" as 'center',
  },
  errorTitle: {
    fontFamily:"Poppins",
    fontSize:"20px",
    fontWeight: 700,
    color:"#1E293B",
    lineHeight:"20px",
  },
  tryAgain:{
    height:"48px",
    fontFamily:"Poppins",
    fontSize:"18px",
    fontWeight: 600,
    backgroundColor:"#527FE2",
    lineHeight:"22px",
    color:"#FFFFFF",
    borderRadius:"8px",
    textTransform: "capitalize" as 'capitalize',
  },
  cancle:{
    height:"48px",
    fontFamily:"Poppins",
    fontSize:"18px",
    fontWeight: 600,
    backgroundColor:"#FFFFFF",
    lineHeight:"22px",
    color:"#527FE2",
    borderRadius:"8px",
    border:"1px solid #527FE2",
    textTransform: "capitalize" as 'capitalize',
  },
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  titleWhySignUp: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  title: {
    marginBottom: 2,
    fontSize: 14,
    textAlign: "left",
    marginTop: 16
  },

  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgDummyPassword: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    opacity: 0.4,
    fontWeight: "bold",
    paddingStart: Platform.OS === "web" ? 0 : 10
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 0,
    padding: 12,
    marginTop: 0
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginTop: 0,
    borderBottomWidth: 1,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  bgMobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
    marginTop: 0,
    paddingStart: Platform.OS === "web" ? 0 : 10
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  helperText: { marginTop: 10 }
});
const webStyle:any = {
    imageStyle: {
      width: 100,
    },
    qualificationBlock: {
      marginTop: 40,
    },
  
    cardStle: {
      minHeight:"100vh",
      maxWidth:"100vw",
      padding:"1.5rem",
      margin:"2rem",
      borderRadius:"1.5rem",
      position:"absolute",
      top:"168px"
  
    },
    imageBOx:{
      padding:"0 1.5rem 0 1.5rem",
      borderRadius:"1.5rem",
      display:"flex",
      gap:"1rem",
      alignItems:"center",
      marginBottom:"40px"
    },
    imageStyle1:{
      width:"130px",
      height:"130px",
      borderRadius:"150px"
    },
    profileName:{
      fontFamily:"Poppins",
      fontWeight:700,
      fontSize:"2rem",
      letterSpacing:"-1%"
    },
    profileCat:{
      fontFamily:"Poppins",
      fontWeight:400,
      fontSize:"1rem",
      color:"#334155",
      display:"flex",
      alignItems:"center",
      columnGap:"8px"
    },
    formHeading:{
      fontFamily:"Poppins",
      fontSize:"20px",
      fontWeight:700,
      paragraph:"12px",
      color:"#334155"
    },
    addBranch:{
      fontFamily:"Poppins",
      fontSize:"16px",
      fontWeight:700,
      color:"#527FE2",
      alignSelf:"center"

    },
    addBranchIcon:{
      height: "20px",
      width:"20px",
      alignSelf:"center"
    },
    branchBox:{
      display:"flex",
      gap:"8px",
      flexDirection:"row",
      textAlign:"center",
      justifyContent:"center",
      cursor:"pointer",
    },
    descText:{
      fontFamily:"Poppins",
      fontSize:"16px",
      fontWeight:400,
      paragraph:"8px",
      color:"#0F172A",
      marginTop:"8px",
    },
    subFields:{
      fontFamily:"Poppins",
      fontSize:"16px",
      fontWeight:700,
      paragraph:"8px",
      color:"#64748B"
    },
    branchName:{
      fontFamily:"Poppins",
      fontSize:"16px",
      fontWeight:700,
      paragraph:"8px",
      color:"#334155",
      marginTop:"1rem"
    }
};

const Modalstyles: { [key: string]: React.CSSProperties } = {
  modal: {
    position:
      'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '8px 8px 32px 8px',
    width:"1024px",
    maxWidth:"98vw"

  },
  subModalDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', flexDirection: 'column',maxHeight:"98vh",overflow:"auto"
  },
  topboxstyle: {
    display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%",
    borderBottom: "1px solid #E2E8F0", padding: "24px 0px"
  },
  dailyWorkingHrs: {
    display: "flex", width: "100%",flexDirection:"column"
  },
  topboxstyle2: {
  
    padding: "24px 0px",width:"100%"
  },
};

  const BoxTime = styled(Box)({
    display:"flex",alignItems:"center",width:"50%",gap:"0.5rem",
    "@media (max-width:700px )": {
     width:"100%",
    },
  });
  
  const BoxTimeTop = styled(Box)({
    padding: "1.5rem 40px 1.5rem 40px",display:"flex",justifyContent:"space-between"  ,
    "@media (max-width:700px )": {
     flexDirection:"column"
    },
          
  });
  
  const HeadingTime = styled(Typography)({
    fontFamily:"Poppins",
                fontWeight:"bold",
                fontSize:"16px"
                
  });

  const TimeRange123 = styled(TextField)({
 
    "& input::placeholder": {
      color: "#94A3B8",
      opacity: 1,
      fontFamily: "Poppins",
      fontSize:"20px",fontWeight:700
    },
  
    "& .MuiInputBase-input[type='date']": {
      lineHeight: "19.2px",
      textTransform: "uppercase",
      color: "#334155", fontSize: "16px",fontWeight:400
    },
  
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "18px",
    },
  
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', 
      },
      '&:hover fieldset': {
        border: 'none', 
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
  
    "& .MuiInputBase-root": {
      color: "black",fontFamily:"Poppins",fontSize:"1rem",paddingLeft:"5px"
    },
  
    "& .MuiOutlinedInput-input": {
      fontSize:"16px",fontWeight:400,padding:"0px"
    },
  
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px",
    },
  
    "@media (max-width: 990px)": {
      minWidth: "unset",
      maxWidth: "unset",
    },
  });

  const HeadingText = styled(Typography)({
    "@media (max-width:1050px )": {
      fontSize: "20px ",
    },
    "@media (max-width:899px )": {
      fontSize: "18",
    },
  
    fontFamily: "Poppins", lineHeight: "25px",
    fontSize: "24px", fontWeight: 700,
     padding: "0 40px"
  
  });
  
  const SubHeadingText = styled(Typography)({
    "@media (max-width:1050px )": {
      fontSize: "18px ",
    },
    "@media (max-width:899px )": {
      fontSize: "16",
    },
  
    fontFamily: "Poppins",
    fontSize: "20px", fontWeight: 700, 
    padding: "0 40px"
  
  });

  const AcceptBtn = styled(Button)({
    maxWidth: "42.5%",
    marginRight:"24px",
    "@media (max-width:1050px )": {
      fontSize: "14px ",
      padding: "12px"
    },
    "@media (max-width:899px )": {
      fontSize: "12",
      padding: "8px"
    },
  
    background:
      "#527FE2",
    width: "100%",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "white",
    fontFamily: "Poppins ",
    fontSize: "1em",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    }
  
  });
  
  const HeadBox = styled(Box)({
    display:"flex",
    "@media (max-width:990px )": {
      flexDirection:"column"
    },
                
  });
  const SubHeadBox = styled(Box)({
    width:"50%",
    "@media (max-width:990px )": {
      width:"100%",
    },
                
  });
  
  const SubFieldRows = styled(Box)({
    marginTop:"1rem",display:"flex",alignItems:"center",gap:"5px",flexWrap:"wrap"
                
  });
  
  const EditBtn = styled(Button)({
    maxWidth: "42.5%",
    marginRight:"24px",
    position:"absolute",
    height:"44px",
    padding:"10px 16px",
    backgroundColor:"#60A5FA",
    top:"40px",
    right:"0px",
    "@media (max-width:1050px )": {
      fontSize: "14px ",
      padding: "12px"
    },
    "@media (max-width:899px )": {
      fontSize: "12",
      padding: "8px"
    },
  
    gap: "8px",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontFamily: "Poppins ",
    fontSize: "1em",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#60A5FA",
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: "#60A5FA",
      boxShadow: 'none',
    }
  
  });
  
  const DescrText = styled(Typography)({
    fontFamily:"Poppins",
    fontSize:"16px",
    fontWeight:400,
    paragraph:"8px",
    color:"#0F172A",
    marginTop:"8px",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },
  });
  
  const SubFieldsText = styled(Typography)({
    fontFamily:"Poppins",
      fontSize:"16px",
      fontWeight:700,
      paragraph:"8px",
      color:"#64748B",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },
  });
  
  const ProfileCatText = styled(Typography)({
    fontFamily:"Poppins",
    fontWeight:400,
    fontSize:"1rem",
    color:"#334155",
    display:"flex",
    alignItems:"center",
    columnGap:"8px",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },})
  
    const ProfileName = styled(Typography)({
      fontFamily:"Poppins",
      fontWeight:700,
      fontSize:"2rem",
      letterSpacing:"-1%",
      "@media (max-width:500px )": {
        fontSize:"1.5rem",
      },
    });
  
    const ImageProfile = styled('img')({
      width:"130px",
      height:"130px",
      borderRadius:"150px",
      "@media (max-width:500px )": {
        width:"100px",
        height:"100px",
        borderRadius:"50%"
      },
    });
  
    const InputField = styled(TextField)({
      "& input::placeholder": {
        color: "#94A3B8", opacity: 1,fontFamily:"Poppins",fontSize: "16px", fontWeight: 400,
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
      
      "& .MuiInputBase-input[type='date']": {
        lineHeight: "19.2px", textTransform: "uppercase",
        color: "#334155",
      },
      "& .MuiFormHelperText-root.Mui-error": {
        color: "#DC2626",
        fontSize: "12px", fontFamily: "Poppins",
        fontWeight: 400, lineHeight: "18px",
      },
      
      "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline" : {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline" : {
        borderColor: "#F87171", 
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px", 
        borderRadius: "8px",
        borderColor: "#CBD5E1",
      },
      "& .MuiInputBase-root":{
        color: "#334155",
      },
      "& .MuiOutlinedInput-input": {
        padding: "20px 8px",
      },
      "& .MuiOutlinedInput-root" : {
        "&:hover fieldset": {

          borderColor:"#6EE7B7",
        },
        "&.Mui-focused fieldset":{
          borderWidth: "1px",
          borderColor: "#6EE7B7",
        },

      },
      "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: "0px", marginRight: "0px",
      },
      "@media (max-width:990px )": {
        minWidth: "unset", 
        maxWidth: "unset",
      },
    });


    const StyledTextarea = styled(TextareaAutosize)({
      width: '100%',
      border: '1px solid #CBD5E1',
      borderRadius: '10px',
      padding: '1rem',
      fontFamily: 'Poppins',
      fontSize: '1rem',
      '&::placeholder': {
        color: '#94A3B8', opacity:1
      },
      
    });

    const SubmitButn = styled(Button)({
      color: "white",
      borderRadius: "10px",
      backgroundColor: "#527FE2",
      textTransform: "none",
      fontFamily: "Poppins",
      fontWeight:"bold",
      fontSize: "24px",
      padding: "6px 31px",
      maxWidth: "400px",
      height: "56px",
      "&:hover": {
        color: "white",
        backgroundColor: "#527FE2",
      },
      "@media(max-width:600px)": {
        fontSize:"18px",
        padding: "7px 27px",
      },
    });

    const SaveBtnBox = styled(Box)({
      display: "flex", flexDirection: "column", alignItems: "center",
      justifyContent: "center", gap: "16px",
      marginTop: "4em",
      "@media (max-width:990px )": {
        display: "flex", flexDirection: "column",
        justifyContent: "start",
      },
    });

    const PhoneStyle = styled(PhoneInput)(({ borderColor }: any) => ({

      border: `1px solid ${borderColor || "#CBD5E1"}`,
      borderRadius: 8,
      height: 56,
      zIndex: 1,
      position: "relative",
      display: "flex",
      alignItems: "center",
      paddingLeft: 10,
      "& input": {
        border: "none",
      },
      "& input:focus": {
        border: "none",
        outline: "none",
      },
      "& .PhoneInputInput": {
        color: "#334155",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        background: "transparent",
    
        lineHeight: "24px",
    
      },
      "& input:focus-visible": {
        border: "none",
        outline: "none",
      },
      "& input::placeholder": {
        color: "#94A3B8", opacity: 1,
      },
      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171",
      },
    
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px", borderRadius: "8px",
      },
      "&:focus-within": {
        borderColor: "#6EE7B7",
      },
     
    
    }));
// Customizable Area End
