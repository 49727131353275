import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import  { isValidPhoneNumber } from "react-phone-number-input";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  NationalId:string;
  VatRegNo:string;
  NationalAddress:string;
  BusinessName:string;
  TradingLicense:string ;
  VATtaxidentifire:string ;
  authorizedCertificate:string ;
  authorizedIDcard:string;
  ibanCertificate:string ;
  TradingLicenseFile:File | null ;
  VATtaxidentifireFile:File | null ;
  authorizedCertificateFile:File | null;
  authorizedIDcardFile:File | null;
  ibanCertificateFile:File | null;
  industryCat:string;
  branchSelection:string;
  branchName:string;
  branchLOcation :string;
  branchContactNo:string;
  branchOperatingHours:string;
  businessDescription:string;
  showMOdaltime:boolean;
  selectedDays:any;
  errors:any;
  fileInput1:React.RefObject<HTMLInputElement>
  fileInput2:React.RefObject<HTMLInputElement>
  fileInput3:React.RefObject<HTMLInputElement>
  fileInput4:React.RefObject<HTMLInputElement>
  fileInput:React.RefObject<HTMLInputElement>
  otherFileInput:React.RefObject<HTMLInputElement>
  InputFileOther:string;
  otherpdfFile: File |null;
  categoryData:any;
  branchNames:any;
  branchLocations:any;
  branchContactNos:any;
  branchOperatingHoursNew:any;
  allBranchData:[];
  branches:any;
  modalBranchIndex: any,
  CustomToken:string|null,
  emailValidation:string,
  responseMess:string;
  startTimeam:string;
  endTimeam:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  getCategoriesApiCallId:string="";
  postBranchAPIcallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      NationalId:"",
      VatRegNo:"",
      NationalAddress:"",
      BusinessName:"",
      TradingLicense:"",
      VATtaxidentifire:"",
      authorizedCertificate:"",
      authorizedIDcard:"",
      ibanCertificate:"",
      industryCat:"",
      branchSelection:'',
      branchName:"",
      branchLOcation:"",
      branchContactNo:"",
      branchOperatingHours:"",
      businessDescription:"",
      showMOdaltime:false,
      selectedDays:[],
      errors:{},
      fileInput1: React.createRef(),
      fileInput2: React.createRef(),
      fileInput3: React.createRef(),
      fileInput4: React.createRef(),
      fileInput:React.createRef(),
      otherFileInput:React.createRef(),
      authorizedIDcardFile:null,
      authorizedCertificateFile:null,
      TradingLicenseFile:null,
      VATtaxidentifireFile:null,
      ibanCertificateFile:null,
      otherpdfFile:null,
      InputFileOther:"",
      categoryData:[],
      branchNames: [{ id: 1, value: "" }],
      branchLocations:[{ id: 1, value: "" }],
      branchContactNos: [{ id: 1, value: "" }],
      branchOperatingHoursNew: [{ id: 1, value: "" }],
      allBranchData:[],
      branches: [
        {
          id: Date.now(),
          branchName: "",
          branchLocation: "",
          branchContactNo: "",
          branchOperatingHours: "",
          selectedDays: [],
          startTime:{hours:"",minutes:"",period:"AM"},
          endTime:{hours:"",minutes:"",period:"AM"},
        },
      ],
      modalBranchIndex: null,
      CustomToken:"",
      emailValidation:"",
      responseMess:"",
      startTimeam:"AM",
      endTimeam:"AM"
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      
       if (apiRequestCallId == this.getCategoriesApiCallId) {
          
          this.setState({categoryData:responseJson.data})
        }


       if (apiRequestCallId == this.editUserApiCallId) {
        if(responseJson && !responseJson.errors)
          {this.setState({errors:{},emailValidation:"",responseMess:responseJson.message})
        
          }else{
            this.setState({emailValidation:responseJson.errors[0]})
          }
     
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let token  = localStorage.getItem("tokenCustom")
    this.setState({CustomToken:token?token:null})
    this.GetCategory()
  }

  onValueChange = (name: string, value: string |null) => {
    this.setState({ ...this.state, [name]: value });
  };



  onValueChangeNew = (index: number, field: string, value: string) => {
    const branches = [...this.state.branches];
    branches[index][field] = value;
    this.setState({ branches });
  };

  validateForm = async() => {
    const schema = this.FormuserSchema();
    schema
      .validate({ NationalId: this.state.NationalId ,VatRegNo:this.state.VatRegNo,NationalAddress:this.state.NationalAddress
        ,BusinessName:this.state.BusinessName,industryCat:this.state.industryCat, branchSelection:this.state.branchSelection,
        branchName:this.state.branchName, branchLOcation:this.state.branchLOcation, branchContactNo:this.state.branchContactNo,
        branchOperatingHours:this.state.branches[0].branchOperatingHours,businessDescription:this.state.businessDescription,
        ibanCertificate:this.state.ibanCertificateFile,authorizedIDcard:this.state.authorizedIDcardFile,VATtaxidentifire:this.state.VATtaxidentifireFile,
        TradingLicense:this.state.TradingLicenseFile,authorizedCertificate:this.state.authorizedCertificateFile,
        branches: this.state.branches,
      }, { abortEarly: false })
      .then(() => {
         this.onPostBranch()
        this.onEditTask()
       
       
      })
      .catch((validationError) => {
        const newErrors: Record<string, string> = {};
        validationError.inner.forEach((error: any) => {
          newErrors[error.path] = error.message;
        });
        this.setState({
          errors: newErrors,
        });
      });
  };
  handleSubmit = (e: any) => {
    e.preventDefault()
    this.validateForm()
  }

  onEditTask = () => {
    this.setState({ loading: true });

    const header = {
      token: localStorage.getItem("tokenCustom"),
    };

    const formData:any = new FormData();
    formData.append("profile[business_name]", this.state.BusinessName);
    formData.append("profile[business_description]", this.state.businessDescription);
    formData.append("profile[category]", this.state.industryCat);
    formData.append("profile[trading_license]", this.state.TradingLicenseFile);
    formData.append("profile[vat_tax_identifier]", this.state.VATtaxidentifireFile);
    formData.append("profile[authorization_certificate]", this.state.authorizedCertificateFile);
    formData.append("profile[authorized_id_card]", this.state.authorizedIDcardFile);
    formData.append("profile[iban_certificate]", this.state.ibanCertificateFile);
    formData.append("profile[address]", this.state.NationalAddress);
    formData.append("profile[support_email]", this.state.email);
    formData.append("profile[national_id]", this.state.NationalId);
    formData.append("profile[vat_registration_number]", this.state.VatRegNo);
    formData.append("profile[other_document]", this.state.otherpdfFile);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editUserApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  

  // MY FUNCTIONS
  NavigatetoLogin=()=>{
    const navigateMessage: Message = new Message(
        getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "EmailAccountLoginBlock"
    );
    navigateMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(navigateMessage)
  
  }



  returnFunctionStart = (event: any, index: any) => {
    const updatedBranches = [...this.state.branches]; 
    updatedBranches[index] = { 
      ...updatedBranches[index], 
      startTime: event.startTime 
    };
    
    this.setState({ branches: updatedBranches });
  };

  returnFunctionEnd = (event: any, index: any) => {
    const updatedBranches = [...this.state.branches]; 
    updatedBranches[index] = { 
      ...updatedBranches[index], 
      endTime: event.endTime 
    };
    
    this.setState({ branches: updatedBranches }); 
  };


  
  handleFileInputClick = () => {
    this.state.fileInput.current?.click();
  };
  handleFileInputClick1 = () => {
    this.state.fileInput1.current?.click();
  };
  handleFileInputClick2 = () => {
    this.state.fileInput2.current?.click();
  };
  handleFileInputClick3 = () => {
    this.state.fileInput3.current?.click();
  };
  handleFileInputClick4 = () => {
    this.state.fileInput4.current?.click();
  };
  handleOtherFileInputClick = () => {
    this.state.otherFileInput.current?.click();
  };
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
    this.setState({ibanCertificate:file?.name,ibanCertificateFile:file})
    console.log(file,"FGHDFGHJ");}
  };

  OtherhandleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
    this.setState({InputFileOther:file?.name,otherpdfFile:file})
    console.log(file,"FGHDFGHJ");}
  };

  handleFileChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
    this.setState({authorizedIDcard:file?.name,authorizedIDcardFile:file})
    console.log(file,"FGHDFGHJ");}
  };

  handleFileChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
    this.setState({authorizedCertificate:file?.name,authorizedCertificateFile:file})
    console.log(file,"FGHDFGHJ");}
  };

  handleFileChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
    this.setState({VATtaxidentifire:file?.name,VATtaxidentifireFile:file})
    console.log(file,"FGHDFGHJ");}
  };

  handleFileChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
    this.setState({TradingLicense:file?.name,TradingLicenseFile:file})
    console.log(file,"FGHDFGHJ");}
  };


  FormuserSchema = () => {
    return Yup.object().shape({
      NationalId: Yup.string() .matches(/^\d+$/, "*Please enter a valid National ID number.")
      .required("*Please enter a valid National ID number.").nullable(),
      VatRegNo: Yup.string().required("*Please enter a valid VAT Registration number."),
      NationalAddress: Yup.string().required("Please enter a valid National Address"),
      BusinessName: Yup.string().required("*Please enter a valid business name"),
      industryCat:Yup.string().required("*Please select your Industry category ."),
      branchSelection:Yup.string().required("*Please choose eithr Individual Branch or Multiple Branches."),
     
      businessDescription:Yup.string().required("*Please provide brief Business Description"),
      TradingLicense: Yup.mixed()
      .required("*Please upload valid Trading License (up to 5mb)")
      .test('fileSize', '*Please upload valid Trading License (up to 5mb)', value => {
        return value && value.size <= 5 * 1024 * 1024; 
      })
      .nullable(),
      VATtaxidentifire: Yup.mixed()
      .required("*Please upload VAT Tax Identifier (up to 5mb)")
      .test('fileSize', '*Please upload VAT Tax Identifier (up to 5mb)"', value => {
        return value && value.size <= 5 * 1024 * 1024; 
      })
      .nullable(),
      authorizedCertificate: Yup.mixed()
      .required("*Please upload the Authorization Certificate (up to 5mb)")
      .test('fileSize', '*Please upload the Authorization Certificate (up to 5mb)', value => {
        return value && value.size <= 5 * 1024 * 1024; 
      })
      .nullable(),
      authorizedIDcard: Yup.mixed()
      .required("*Please upload Authorized ID Card (up to 5mb)")
      .test('fileSize', '*Please upload Authorized ID Card (up to 5mb)', value => {
        return value && value.size <= 5 * 1024 * 1024; 
      })
      .nullable(),
      ibanCertificate: Yup.mixed()
      .required("*Please upload the IBAN certificate (up to 5mb)")
      .test('fileSize', '*Please upload the IBAN certificate (up to 5mb)', value => {
        return value && value.size <= 5 * 1024 * 1024; 
      })
      .nullable(),
      branches: Yup.array().of(
        Yup.object().shape({
          branchName: Yup.string().required("*Please enter the Branch Name"),
          branchLocation: Yup.string().required("*Please provide the Branch Location"),
          branchContactNo: Yup.string()
            .test('is-valid-phone', '*Please enter a valid contact number for the branch', function (value) {
              return isValidPhoneNumber(value || '');
            })
            .required('*Please enter a valid contact number for the branch'),
          branchOperatingHours: Yup.string().required("*Please specify the Branch Operating Hours"),
          selectedDays: Yup.array().of(Yup.string()).required('*Please select at least one day'),
         
        })
      ).min(1, "*At least one branch is required")
    });
  };
  
  GetCategory = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  addBranch = () => {
    const newBranch = {
      id: Date.now(),
      branchName: "",
      branchLocation: "",
      branchContactNo: "",
      branchOperatingHours: "",
      selectedDays: [],
      startTime:{hours:"",minutes:"",period:"AM"},
      endTime:{hours:"",minutes:"",period:"AM"},
    };
    this.setState({ branches: [...this.state.branches, newBranch] });
  };

  deleteBranch = (delbtnindex: number) => {
    this.setState((prevState) => {
      const updatedBranches = prevState.branches.filter((_:any, index:any) => index !== delbtnindex);
      return { branches: updatedBranches };
    });
  };

  handleShowTime = (index: number) => {
    this.setState({ showMOdaltime: true, modalBranchIndex: index });
  };

  handleHideshowTime = () => {
    this.setState({ showMOdaltime: false, modalBranchIndex: null });
  };



  handleCheckboxChange = (index:number, day:string, isChecked:any) => {
    this.setState((prevState) => {
      const updatedBranches = [...prevState.branches];
      const branch = updatedBranches[index];
  
      if (isChecked && branch) {
        branch.selectedDays  = [...branch.selectedDays, day];
      } else if(branch) {
        branch.selectedDays = branch.selectedDays.filter((selectedDay:any) => selectedDay !== day);
      }
      
      updatedBranches[index] = branch;
  
      return { branches: updatedBranches };
    });
  };

  onHandleSubmitTime = () => {
    const { modalBranchIndex, branches } = this.state;
    if (modalBranchIndex !== null) {

      const start = `${branches[modalBranchIndex].startTime.hours}:${branches[modalBranchIndex].startTime.minutes} ${branches[modalBranchIndex].startTime.period}`;
      const end =`${branches[modalBranchIndex].endTime.hours}:${branches[modalBranchIndex].endTime.minutes} ${branches[modalBranchIndex].endTime.period}`;

      branches[modalBranchIndex].branchOperatingHours = `From ${start} To ${end}`;
      this.setState({ branches, showMOdaltime: false });
    }
  };



  onPostBranch = () => {
    const header = {
      token: localStorage.getItem("tokenCustom"),
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const data = this.state.branches.map((item: any) => ({
      branch_name: item.branchName,
      branch_location: item.branchLocation,
      branch_contact_number: item.branchContactNo,
      branch_operating_hours: item.branchOperatingHours,
      branch_code: "12345",
    }));

    const dataSend = { branches: data };
    this.postBranchAPIcallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataSend)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/profile/branches`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  branchValueReset=()=>{
    if(this.state.branchSelection=="0")
      {
        this.setState({branches:  [
          {
            id: Date.now(),
            branchName: "",
            branchLocation: "",
            branchContactNo: "",
            branchOperatingHours: "",
            selectedDays: [],
            startTime:{hours:"",minutes:"",period:"AM"},
            endTime:{hours:"",minutes:"",period:"AM"},
          },
        ],})
      }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.branchSelection !=this.state.branchSelection){
      this.branchValueReset()
    }
  }

  handlePeriodChange(event:any, branchId:any) {
    this.setState((prevState) => ({
      branches: prevState.branches.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              startTime: {
                ...branch.startTime,
                period: event.target.value, // Update only the period
              },
            }
          : branch
      ),
    }));
  }
  handleMinuteChange (event:any, branchId:any) {
    this.setState((prevState) => ({
      branches: prevState.branches.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              startTime: {
                ...branch.startTime,
                minutes: event.target.value, // Update only the period
              },
            }
          : branch
      ),
    }));
  }

  handleHourChange (event:any, branchId:any) {
    this.setState((prevState) => ({
      branches: prevState.branches.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              startTime: {
                ...branch.startTime,
                hours: event.target.value, // Update only the period
              },
            }
          : branch
      ),
    }));
  }


  handlePeriodChangeEnd(event:any, branchId:any) {
    this.setState((prevState) => ({
      branches: prevState.branches.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              endTime: {
                ...branch.endTime,
                period: event.target.value, // Update only the period
              },
            }
          : branch
      ),
    }));
  }
  handleMinuteChangeEnd (event:any, branchId:any) {
    this.setState((prevState) => ({
      branches: prevState.branches.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              endTime: {
                ...branch.endTime,
                minutes: event.target.value, // Update only the period
              },
            }
          : branch
      ),
    }));
  }

  handleHourChangeEnd (event:any, branchId:any) {
    this.setState((prevState) => ({
      branches: prevState.branches.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              endTime: {
                ...branch.endTime,
                hours: event.target.value, // Update only the period
              },
            }
          : branch
      ),
    }));
  }
  
  // Customizable Area End
}
