import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  selectedImage: any;
  contactNumber: any;
  successDialog:boolean;
  setDepartmentData: any;
  setPositionData: any;
  setPosition: any;
  setDepartment: any;
  setReporting: any;
  setBranchData: any;
  setReportingData:any;
  resetState: ()=> void;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupsApiCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  getDepartmentDataCallId = "";
  getPositionDataCallId = "";
  getBranchDataCallId = "";
  getReportingPersonCallId = "";
  addEmployeeCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      selectedImage: null,
      contactNumber: '',
      successDialog:false,
      setDepartmentData : [],
      setPositionData: [],
      setPosition: '',
      setDepartment: '',
      setReporting: '',
      setBranchData: [],
      setReportingData: [],
      resetState: () => {}
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getDepartmentList();
    this.getBranchData();
  }

  receive = async (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getDepartmentDataCallId) {
      if (responseJson ) {
        this.setState({ setDepartmentData: responseJson.data})
      }
    }
    
    if (apiRequestCallId === this.getPositionDataCallId) {
      if (responseJson && Array.isArray(responseJson)) {
        this.setState({ setPositionData: responseJson}); 
      } else {
        this.setState({ setPositionData: [], setPosition: ""});
        //Check Error Response
      }
    }

    if (apiRequestCallId === this.getBranchDataCallId) {
      if (responseJson ) {
        this.setState({ setBranchData: responseJson.branches})
      }
    }
    this.getReportingPersonResponce(apiRequestCallId,responseJson)
    this.addEmployeeResponce(apiRequestCallId,responseJson)
    
  }

  getReportingPersonResponce = (apiRequestCallId: string,responseJson: any) => {
    if (apiRequestCallId === this.getReportingPersonCallId) {
      if (responseJson ) {
        this.setState({ setReportingData: responseJson.employees.data})
      }
    }
  } 

  addEmployeeResponce = (apiRequestCallId: string,responseJson: any) => {
    if (apiRequestCallId === this.addEmployeeCallId) {
      if (!responseJson.error && responseJson) {
        this.setState({ selectedImage: null, successDialog: true });
        this.handleNavigation();
        this.state.resetState();
      } else{
        toast.error(responseJson.error.en || responseJson.error[0]);
      }
    }
  }

  handleImageUpload = (event:any) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ selectedImage: file });
    }
  };

  contactPackage = (values:any,name:any) => {
    this.setState({ contactNumber: values})
  }

  handleClose = () => {
    this.setState({ successDialog: false});
  };

  apiCall = ({ endpoint = "", method = "GET", headers = {}, body = null }) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const callId = requestMessage.messageId;
  
    // Set endpoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    // Set headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    // Set body
    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
  
    // Set method
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
  
    // Send the request
    runEngine.sendMessage(callId, requestMessage);
    return callId;
  };

  getDepartmentList = async () => {
    const token = await getStorageData("token");
    
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
  
    this.getDepartmentDataCallId = this.apiCall({
      endpoint: configJSON.getDepartmentApi,
      method: configJSON.getApiMethod,
      headers,
    });
  }

  getPositonData = async (id:string) => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPositionDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_fullteamvisibility/departments/${id}/designations`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBranchData = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBranchDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBranchApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getReportingPersonData = async (id:string) => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportingPersonCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_fullteamvisibility/employees_in_branch/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChange = (title:string,event: React.ChangeEvent<{ value: unknown }>) => {    
    if(title === 'department'){
      this.setState({ setDepartment : event.target.value as string})
      this.getPositonData(event.target.value as string)
    }
    if(title === 'reporting'){
      this.setState({ setReporting : event.target.value as string})
    }
    if(title === 'position'){
      this.setState({ setPosition : event.target.value as string})
    }
    if(title === 'branch'){
      this.getReportingPersonData(event.target.value as string);
      // this.setState({ setReporting : event.target.value as string})
    }
  };

  addEmployee = async (values:any) => {
    const token = await getStorageData("token");
    const header = {
      token
    };

    const formData = new FormData();
    formData.append("employee[name]", values.employeeName);
    formData.append("employee[department_id]", values.department);
    formData.append("employee[designation_id]", values.position);
    formData.append("employee[mobile_number]", values.contactNumber);
    formData.append("employee[work_email]", values.email);
    formData.append("employee[home_address]", values.homeAddress);
    formData.append("employee[branch_id]", values.branch);
    formData.append("employee[organization_id]", values.organizationId);
    formData.append("employee[reporting_to]", values.reportingTo);
    formData.append("employee[additional_notes]", values.additionalNotes);
    formData.append("employee[profile_picture]", this.state.selectedImage);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addEmployeeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addEmployeeApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onSubmit = (values:any, resetForm:()=>void) => {
    if(this.state.selectedImage != null){
      this.addEmployee(values);
    } else{
      toast.error("Please Select Profile")
    }
    this.setState({resetState:resetForm})
  };

  handleNavigation = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AccountGroups");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}