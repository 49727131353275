import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import {switchLanguage} from "../../../components/src/languageJson/i18n";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  currentLanguage:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountType: string;
  currentLanguage:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class SuccessConfirmController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpToken: any;
  //Properties from config
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.state = {
      token: "",
      accountType: "",
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
    };
    // Customizable Area End
  }
  // Customizable Area Start

  NavigatetoForgotPassword = () => {
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ForgotPassword"
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMessage)
  }

  NavigateToLogIn = () => {
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMessage)
  }

  switchToArabic = () => {
    switchLanguage("ar");
  };

  switchToEnglish = () => {
    switchLanguage("en");
  };
  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };
  
  

  // Customizable Area End
}
