export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const forgotImg = require("../assets/forgotImg.png");
export const lockImg = require("../assets/lock1.png");
export const passImg = require("../assets/pass.png");
export const backGroundImg = require("../assets/background.jpeg");
export const back = require("../assets/backButton.svg");
export const otpForgot = require("../assets/otpImg.svg");
export const helpTitle = require("../assets/helpTitle.svg");
export const checked = require("../assets/checkedCheck.svg");
export const unChecked = require("../assets/uncheck.svg");
export const arBackButton = require("../assets/arBackButton.svg");