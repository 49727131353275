import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Modal,
  Typography,
  styled,
} from "@material-ui/core";

import { Close } from "@material-ui/icons";
import { I18n } from "../../../components/src/languageJson/i18n";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  styles: { [key: string]: React.CSSProperties } = {
    subModalDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center', flexDirection: 'column'
    },
    topboxstyle: {
      display: "flex", 
      justifyContent: "space-between", 
      flexDirection:this.getValueBasedOnLanguage("row-reverse", "row"),
      alignItems: "center", width: "100%",
      borderBottom: "1px solid #E2E8F0", 
      padding: "24px 0px",
      paddingLeft: this.getValueBasedOnLanguage("30px", "")
    },
    buttonBox: {
      display: "flex",
      flexDirection:this.getValueBasedOnLanguage("row-reverse", "row"),
      gap: "1em",
      borderTop: "1px solid #E2E8F0",
      padding: "1.5em",
      borderRadius: "0px 0px 32px 8px"
    },
  };
  DeclineBtn = styled(Button)({
    "@media (max-width:1050px )": {
      fontSize: "14px ",
      padding: "12px"
    },
    "@media (max-width:899px )": {
      fontSize: "12",
      padding: "8px"
    },
    width: "100%",
    height:"56px",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "#527FE2",
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    fontSize: "1em ",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none",
    direction:this.getValueBasedOnLanguage("rtl", "ltr"),
    '&:hover': {
      backgroundColor: "#fffff",
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: "#fffff",
      boxShadow: 'none',
    }
  });
  
  AcceptBtn = styled(Button)({
    "@media (max-width:1050px )": {
      fontSize: "14px ",
      padding: "12px"
    },
    "@media (max-width:899px )": {
      fontSize: "12",
      padding: "8px"
    },
    background: "#527FE2",
    width: "100%",
    height:"56px",
    padding: "16px",
    gap: "8px",
    borderRadius: "8px",
    color: "white",
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    fontSize: "1em",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "none",
    direction:this.getValueBasedOnLanguage("rtl", "ltr"),
    '&:hover': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    }
  });

  SubHeadingText = styled(Typography)({
    "@media (max-width:1050px )": {
      fontSize: "14px ",
    },
    "@media (max-width:899px )": {
      fontSize: "12",
    },
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    lineHeight: "25px",
    fontSize: "16px",
    padding: "24px 16px 24px 40px",
    color: "#0F172A", 
    fontWeight: 400,
    direction: this.getValueBasedOnLanguage("rtl","ltr"),
  });
  
  HeadingText = styled(Typography)({
    "@media (max-width:1050px )": {
      fontSize: "20px ",
    },
    "@media (max-width:899px )": {
      fontSize: "18",
    },
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    lineHeight: "25px",
    fontSize: "24px", 
    fontWeight: 700, 
    padding: "0 40px",
    direction: this.getValueBasedOnLanguage("rtl","ltr"),
  });
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (

    <>
      <Modal
            open={!!this.state.termsData && this.props.showTermsModal}
            onClose={this.props.handleHideTerms}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <ModalBox>
              <Box style={this.styles.subModalDiv}>
                <Box style={this.styles.topboxstyle}>
              <this.HeadingText >
              {I18n.t("termsAndConditions")}
                </this.HeadingText>
                <Close style={{ paddingRight: "1rem" }} onClick={this.props.handleHideTerms} />
              </Box>
              <OveFlowBox>
                <this.SubHeadingText data-test-id="termsandconditions">
                  {this.state.currentLanguage === "ar" ? this.state.termDataArb:this.state.termsData}
                </this.SubHeadingText>
              </OveFlowBox>
            </Box>
            <Box style={this.styles.buttonBox}>
              <this.DeclineBtn
                variant="outlined"
                data-test-id="nextBtnTestId"
                onClick={this.props.handleCheckboxReject}
              >
                 {I18n.t("decline")}
              </this.DeclineBtn>
              <this.AcceptBtn
                variant="contained"
                data-test-id="nextBtnTestId"
                onClick={this.props.handleCheckboxAccept}
              >
                 {I18n.t("accept")}
              </this.AcceptBtn>
            </Box>
          </ModalBox>
        </Modal>

        <Modal
          open={!!this.state.privacyPolicyData && !!this.props.showPolicyModal}
          onClose={this.props.handleHidePrivacyPolicy}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <ModalBox>
            <Box style={this.styles.subModalDiv}>
              <Box style={this.styles.topboxstyle}>
                <this.HeadingText >
                {I18n.t("privacyPolicy")}
                </this.HeadingText>
                <Close style={{ paddingRight: "1rem" }} onClick={this.props.handleHidePrivacyPolicy} />
              </Box>
              <OveFlowBox>
                <this.SubHeadingText data-test-id="privacyPolicy">
                  {this.state.currentLanguage === "ar" ? this.state.privacyPolicyDataArb:this.state.privacyPolicyData}
                </this.SubHeadingText>
              </OveFlowBox>
            </Box>
            <Box style={this.styles.buttonBox}>
              <this.DeclineBtn
                variant="outlined"
                data-test-id="nextBtnTestId"
                onClick={this.props.handlePrivacyPolicyReject}
              >
                {I18n.t("decline")}
              </this.DeclineBtn>
              <this.AcceptBtn
                variant="contained"
                data-test-id="nextBtnTestId"
                onClick={this.props.handlePrivacyPolicyAccept}
              >
               {I18n.t("accept")}
              </this.AcceptBtn>
            </Box>
          </ModalBox>
        </Modal>
      </>
    )

    // Customizable Area End
  }
}

// Customizable Area Start

const OveFlowBox = styled(Typography)({
  maxHeight: "650px",
  overflowY: "auto",
  scrollbarColor: "#527FE2 #DBEAFE",
  "@media (max-width:1280px) and (min-width:720px)": {
    maxHeight: "550px",
  },
  "@media (max-width:1536px) and (min-width:836px)": {
    maxHeight: "650px",
  },
  "@media (max-width:1180px) and (min-width:720px)": {
    maxHeight: "550px",
  },
  "@media (max-width:720px) and (min-width:610px)": {
    maxHeight: "500px"
  },
  "@media (max-width:610px) and (min-width:410px)": {
    maxHeight: "400px",
  },
  "@media (max-width:810px) and (min-width:700px)": {
    maxHeight: "400px",
  },
  "@media (max-width:410px) and (min-width:360px)": {
    maxHeight: "350px",
  },
});

const ModalBox = styled(Typography)({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '8px 8px 32px 8px',
  maxHeight:"849px",
  width:"1024px",
  outline:"none",
  scrollbarColor: "#527FE2 #DBEAFE",
  "@media (max-width:1280px) and (min-width:720px)": {
    width:"1000px",
  },
  "@media (max-width:1536px) and (min-width:836px)": {
    width:"1024px",
  },
  "@media (max-width:1180px) and (min-width:720px)": {
    width:"850px",
  },
  "@media (max-width:720px) and (min-width:610px)": {
    width:"550px",
  },
  "@media (max-width:610px) and (min-width:410px)": {
    width:"500px",
  },
  "@media (max-width:810px) and (min-width:700px)": {
    width:"650px",
  },
  "@media (max-width:410px) and (min-width:360px)": {
    width:"300px",
  },
});

// Customizable Area End
