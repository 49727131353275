import React from "react";

// Customizable Area Start
import {
  Box,
  Breadcrumbs,
  Dialog,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { I18n } from "../../../components/src/languageJson/i18n";
import * as Yup from 'yup';
import { branchIcon, cameraImg, checkMark, departmentIcon, emailIcon, empIcon, idIcon, locationIcon, positionIcon, redBranchIcon, redDepartmentIcon, redEmailIcon, redEmpIcon, redPositionIcon, reportIcon } from "./assets";
import { ErrorMessage, Form, Formik } from "formik";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PhoneInputWithCountrySelect, {isValidPhoneNumber} from "react-phone-number-input";
import AppNavigation from '../../../components/src/AppNavigation.web';
// Customizable Area End

import AddEmployeeController, {
  Props,
} from "./AddEmployeeController";
export default class AddEmployee extends AddEmployeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  initialValues = {
    employeeName: '',
    ID: '',
    organizationId: '',
    department: '',
    branch: '',
    position: '',
    reportingTo: '',
    homeAddress: '',
    email: '',
    contactNumber: '',
    additionalNotes: ''
  };

  validationSchema = Yup.object({
    employeeName: Yup.string().required('Please enter an Employee name.'),
    // organizationId: Yup.string().required('Please add Organization ID'),
    department: Yup.string().required('Please select a department'),
    branch: Yup.string().required('Please select a Branch.'),
    position: Yup.string().required('Please select a position.'),
    // reportingTo: Yup.string().required('Please select a an Employee to report to.'),
    email: Yup.string().email('Please enter a valid email address.'),
    contactNumber: Yup.string()
        .required('Contact number is required')
        .test(
            'isValidPhoneNumber',
            'Enter a valid phone number',
            (value) => isValidPhoneNumber(value || '') // Validates using react-phone-number-input
        )
  });

  uploadAndShowImage = () => (
    this.state.selectedImage ? (
      <img
        src={URL.createObjectURL(this.state.selectedImage)}
        alt="Selected"
        className='imagePreview'
      />
    ) : (
      <>
        <div className='iconContainer'><img src={cameraImg} alt="camImg" /></div>
        <div className='text'>Add Picture</div>
      </>
    )
  )

  showCondtion = (errors: any, touched: any) => {
    return Boolean(errors && touched)
  }

  conditionalRenderObject(condition:any,trueStmt:any,falseStmt:any){
    return condition ? condition[trueStmt] : falseStmt
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainBox>
        <AppNavigation />
        <StyledBox>
        <div style={{ paddingBottom: '50px' }}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Typography color="inherit">
            {I18n.t("home")}
            </Typography>
            <Typography color="inherit">
            {I18n.t("peopleManagement")}
            </Typography>
            <Typography color="primary" style={{ fontWeight: '700'}}>
            {I18n.t("employeeProfile")}
            </Typography>
          </Breadcrumbs>
        </div>

        <FormikContainer>
          <Formik initialValues={this.initialValues} validationSchema={this.validationSchema}
            onSubmit={(values, { resetForm }) => {
              this.onSubmit(values, resetForm)
            }}
          >
            {({ errors, touched, handleChange, setFieldValue, values }) => (
              <Form>

                <MainTableDiv>
                  <Typography className="empInfo">Employee Information</Typography>
                  <div className='container'>
                    <label htmlFor="upload-button" className='uploadLabel'>
                      {this.uploadAndShowImage()}
                    </label>
                    <input
                      id="upload-button"
                      type="file"
                      accept="image/"
                      className='hiddenInput'
                      data-test-id='selectImage'
                      onChange={this.handleImageUpload}
                    />
                  </div>
                  <div className="form-group">
                    <div className="setTextField">
                      <div style={{ width: '100%' }}>
                        <TextMain
                          variant="outlined"
                          onChange={handleChange}
                          name="employeeName"
                          type='text'
                          error={this.showCondtion(errors.employeeName, touched.employeeName)}
                          placeholder='Employee Name*'
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {
                                  this.showCondtion(errors.employeeName, touched.employeeName) ?
                                    <img src={redEmpIcon} alt="red_emp_icon" /> : <img src={empIcon} alt="emp_icon" />
                                }
                              </InputAdornment>
                            ),
                          }}
                          value={values.employeeName}
                        />
                        <ErrorMessage name="employeeName" component="div" className="error-message" />
                      </div>
                      <div style={{ width: '100%' }}>
                        <TextMain
                          variant="outlined"
                          onChange={handleChange}
                          name="organizationId"
                          type='text'
                          placeholder="Organization Id"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                  <img src={idIcon} alt="emp_icon" />
                              </InputAdornment>
                            ),
                          }}
                          value={values.organizationId}
                        />
                      </div>
                    </div>
                    <div className="setTextField">
                      
                      <div style={{ width: '100%' }}>
                        <FormControl className="testClassss" variant="outlined" fullWidth>
                          <SelectMain
                            style={{ borderRadius: '10px' }}
                            MenuProps={{                              
                              PaperProps:{
                                style: {
                                  'borderRadius':'10px'
                                },
                              }
                            }}
                            className="helloSelect"
                            label="Select here"
                            name="department"
                            data-test-id='department_id'
                            displayEmpty
                            error={this.showCondtion(errors.department, touched.department)}
                            onChange={(event) => {
                              handleChange(event)
                              this.handleChange("department", event)
                            }}
                            value={values.department}
                            startAdornment={
                              <InputAdornment position="start">
                                {
                                  this.showCondtion(errors.department, touched.department) ?
                                    <img src={redDepartmentIcon} alt="dep_icon" /> : <img src={departmentIcon} alt="dep_icon" />
                                }
                              </InputAdornment>
                            }
                            renderValue={(value) => {
                              const selectedDepartment = this.state.setDepartmentData?.find(
                                (item: any) => item.id === value
                              );
                              return (
                                <Box
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    color: "currentColor",
                                    opacity: this.conditionalRenderObject(selectedDepartment,"1.22","0.42"),
                                    background:'transparent'
                                  }}
                                >
                                  {selectedDepartment ? selectedDepartment.attributes.name : "Department *"}
                                </Box>
                              );
                            }}
                            input={<OutlinedInput />}
                          >
                            {
                              this.state.setDepartmentData && this.state.setDepartmentData.map((item:any,index:number)=>
                                (
                                  <MenuItemSet style={{
                                    borderBottom:this.conditionalRenderObject(index === this.state.setDepartmentData.length - 1,"none","1px solid #d4d2cd")
                                  }}  className="setMenuItem" key={index} value={item?.id}>{item?.attributes?.name}</MenuItemSet>
                                )
                              )
                            }
                          </SelectMain>
                        </FormControl>
                        <ErrorMessage name="department" component="div" className="error-message" />
                      </div>
                      <div style={{ width: '100%' }}>
                        <FormControl variant="outlined" fullWidth>
                          <SelectMain
                            style={{ borderRadius: '10px' }}
                            MenuProps={{                              
                              PaperProps:{
                                style: {
                                  'borderRadius':'10px'
                                },
                              }
                            }}
                            label="Select here"
                            name="branch"
                            data-test-id='branch_id'
                            displayEmpty
                            error={this.showCondtion(errors.branch, touched.branch)}
                            onChange={(event) => {
                              handleChange(event)
                              this.handleChange("branch", event)
                            }}
                            value={values.branch}
                            startAdornment={
                              <InputAdornment position="start">
                                {
                                  this.showCondtion(errors.branch, touched.branch) ?
                                    <img src={redBranchIcon} alt="branch_icon" /> : <img src={branchIcon} alt="branch_icon" />
                                }
                              </InputAdornment>
                            }
                            renderValue={(value) => {
                              const selectedBranch = this.state.setBranchData?.find(
                                (item: any) => item.id === value
                              );
                              return (
                                <Box
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    color: "currentColor",
                                    opacity: this.conditionalRenderObject(selectedBranch,"1.22","0.42"),
                                  }}
                                >
                                  {this.conditionalRenderObject(selectedBranch,'name',"Branch *")}
                                </Box>
                              );
                            }}
                            input={<OutlinedInput />}
                          >
                            {
                              this.state.setBranchData && this.state.setBranchData.map((item:any,index:number)=>
                                (
                                  <MenuItemSet style={{
                                    borderBottom: this.conditionalRenderObject(index === this.state.setBranchData.length - 1,"none","1px solid #d4d2cd"),
                                  }} key={index} value={item?.id}>{item?.name}</MenuItemSet>
                                )
                              )
                            }
                          </SelectMain>
                        </FormControl>
                        <ErrorMessage name="branch" component="div" className="error-message" />
                      </div>
                    </div>
                    <div className="setTextField">
                      
                      <div style={{ width: '100%' }}>
                        <FormControl variant="outlined" fullWidth>
                          <SelectMain
                            label="Select here"
                            MenuProps={{                              
                              PaperProps:{
                                style: {
                                  'borderRadius':'10px'
                                },
                              }
                            }}
                            style={{ borderRadius: '10px' }}
                            name="position"
                            data-test-id='position_id'
                            displayEmpty
                            error={this.showCondtion(errors.position, touched.position)}
                            onChange={(event) => {
                              handleChange(event)
                              this.handleChange("position", event)
                            }}
                            value={values.position}
                            startAdornment={
                              <InputAdornment position="start">
                                {
                                  this.showCondtion(errors.position, touched.position) ?
                                    <img src={redPositionIcon} alt="pos_icon" /> : <img src={positionIcon} alt="pos_icon" />
                                }
                              </InputAdornment>
                            }
                            renderValue={(value) => {
                              const selectedPosition = this.state.setPositionData?.find(
                                (item: any) => item.id === value
                              );
                              return (
                                <Box
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    color: "currentColor",
                                    opacity: this.conditionalRenderObject(selectedPosition,"1.22","0.42"),
                                  }}
                                >
                                  {this.conditionalRenderObject(selectedPosition,'name',"Position *")}
                                </Box>
                              );
                            }}
                            input={<OutlinedInput />}
                          >
                            {
                              this.state.setPositionData && this.state.setPositionData.map((item: any, index: number) =>
                              (
                                <MenuItemSet style={{
                                  borderBottom: this.conditionalRenderObject(index === this.state.setPositionData.length - 1,"none","1px solid #d4d2cd"),
                                }} key={index} value={item.id} > {item.name} </MenuItemSet>
                              )
                              )
                            }
                          </SelectMain>
                        </FormControl>
                        <ErrorMessage name="position" component="div" className="error-message" />
                      </div>
                      <div style={{ width: '100%' }}>
                        <FormControl variant="outlined" fullWidth>
                          <SelectMain
                            label="Select here"
                            style={{ borderRadius: '10px' }}
                            MenuProps={{                              
                              PaperProps:{
                                style: {
                                  'borderRadius':'10px'
                                },
                              }
                            }}
                            name="reportingTo"
                            data-test-id='report_id'
                            displayEmpty
                            onChange={(event) => {
                              handleChange(event)
                              this.handleChange("reporting", event)
                            }}
                            value={values.reportingTo}
                            startAdornment={
                              <InputAdornment position="start">
                                <img src={reportIcon} alt="report_icon" />
                              </InputAdornment>
                            }
                            renderValue={(value) => {
                              const selectedBranch = this.state.setReportingData?.find(
                                (item: any) => item.id === value
                              );
                              return (
                                <Box
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    color: "currentColor",
                                    opacity: this.conditionalRenderObject(selectedBranch,"1.22","0.42"),
                                  }}
                                >
                                  {selectedBranch ? selectedBranch.attributes.name : "Reporting to"}
                                </Box>
                              );
                            }}
                            input={<OutlinedInput />}
                          >
                            {
                              this.state.setReportingData && this.state.setReportingData.map((item:any,index:number)=>
                                (
                                  <MenuItemSet style={{
                                    borderBottom: this.conditionalRenderObject(index === this.state.setReportingData.length - 1,"none","1px solid #d4d2cd"),
                                  }} key={index} value={item?.id}>{item?.attributes?.name}</MenuItemSet>
                                )
                              )
                            }
                          </SelectMain>
                        </FormControl>
                      </div>
                    </div>
                    <div className="setTextField">
                      
                      <div style={{ width: '100%' }}>
                        <TextMain
                          variant="outlined"
                          onChange={handleChange}
                          name="homeAddress"
                          type='text'
                          placeholder='Home address'
                          value={values.homeAddress}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={locationIcon} alt="location" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <TextMain
                          variant="outlined"
                          onChange={handleChange}
                          name="email"
                          type='text'
                          value={values.email}
                          error={this.showCondtion(errors.email, touched.email)}
                          placeholder='Email '
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {
                                  this.showCondtion(errors.email, touched.email) ?
                                    <img src={redEmailIcon} alt="red_emp_icon" /> : <img src={emailIcon} alt="emp_icon" />
                                }
                              </InputAdornment>
                            ),
                          }}
                        />
                        <ErrorMessage name="email" component="div" className="error-message" />
                      </div>
                    </div>
                    <div className="setTextField">
                      
                      <div style={{ width: '100%' }}>
                        <PhoneInputWithCountrySelect
                          className={this.showCondtion(errors.contactNumber, touched.contactNumber) ?"phoneInputChange":'phoneInput'}
                          placeholder="Contact Number *"
                          data-test-id="contact_id"
                          value={values.contactNumber}
                          defaultCountry="AE"
                          countries={[]}
                          countryCallingCodeEditable={false}
                          onChange={(value) => setFieldValue("contactNumber", value)}
                        />
                        <ErrorMessage name="contactNumber" component="div" className="error-message" />
                      </div>
                    </div>
                    <StyledTextarea name="additionalNotes" onChange={handleChange} value={values.additionalNotes} aria-label="minimum height" style={{ width: '100%', marginTop: '20px', borderRadius: '10px', border: '1px solid #B9AEAEFC',padding:'10px' }} minRows={5} placeholder="Additional notes..." />
                  </div>
                </MainTableDiv>
                <div style={{ textAlign: 'center' }}>
                  <button className="saveButton" type="submit">Save</button>
                </div>

              </Form>

            )}
          </Formik>
        </FormikContainer>

          <ToastContainer />
        <SuccessDialog
          open={this.state.successDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="adddynamic"
        >
          <div>
            <div style={{ textAlign: 'center'}}>
              <img src={checkMark} alt="check_mark" />
            </div>
            <div style={{ textAlign: 'center',marginTop:'20px',}}>
              <Typography className="setEmpText">Employee added successfully.</Typography>
            </div>
            <div style={{ textAlign: 'center' }}>
                  <button className="viewEmpButton">View Employee Profile</button>
            </div>
          </div>
        </SuccessDialog>
        </StyledBox>

      </MainBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainBox = styled(Box)({
  display:'flex',
  // height: '100vh'
});

const StyledBox = styled(Box)({
  flex: 1,
  background: '#F1F5FC',
  padding: '50px 20px',
});

const MainTableDiv = styled(Box)({
  marginTop: '20px',
  padding: '20px',
  border: '1px solid #c8c8c8',
  borderTopLeftRadius: '20px',
  borderBottomLeftRadius: '20px',
  background: 'white',
  '& .empInfo':{
    fontWeight: 700
  },
  '& .container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '130px',
    height: '130px',
    borderRadius: '50%',
    border: '2px solid #d3d3d3',
    color: '#8c8c8c',
    cursor: 'pointer',
    textAlign: 'center',
    background:'#E2E8F0',
    marginTop:'30px'

  },
  '& .uploadLabel': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  '& .iconContainer': {
    fontSize: '24px', // Adjust size for the icon
  },
  '& .text': {
    fontSize: '12px',
    marginTop: '5px',
    fontWeight: 700,
    color: '#64748b',
  },
  '& .hiddenInput': {
    display: 'none',
  },
  '& .imagePreview': {
    width: '100%',
    height: '130px',
    objectFit: 'cover',
    borderRadius: '50%'
  },
});

const FormikContainer = styled(Box)({
  marginTop: '20px',
  '& .error-message': {
    color: 'red',
    fontSize: '0.875rem'
  },
  '& .phoneInput':{
    border: '1px solid #B9AEAEFC',
    // borderColor:'rgba(255, 255, 255, 0.51)',
    padding: '17px',
    borderRadius: '10px',
    '& .PhoneInputInput':{
      border: 'none',
      outline: 'none'
    }
  },
  '& .phoneInputChange':{
    border: '1px solid red',
    // borderColor:'rgba(255, 255, 255, 0.51)',
    padding: '17px',
    borderRadius: '10px',
    '& .PhoneInputInput':{
      border: 'none',
      outline: 'none'
    }
  },
  '& .setTextField': {
    display: 'flex',
    gap: '15px',
    marginTop: '20px',
    '@media (max-width: 600px)': {
      flexWrap: 'wrap'
    }
  },
  '& .saveButton': {
    // width: '100%',
    padding: '15px 130px',
    marginTop:'90px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700
  }
});

const TextMain = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    "& .Mui-error":{
      borderColor:"red"
    },
  }
});

const SuccessDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none'
    }
  },
  '& .setEmpText':{
    fontWeight:700
  },
  '& .viewEmpButton': {
    // width: '100%',
    padding: '10px 110px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700
  }
});

const MenuItemSet = styled(MenuItem)({
  padding: '10px',
  '&:hover': {
    backgroundColor: '#DBEAFE',
  },
})

const SelectMain = styled(Select)({
  '& .MuiSelect-outlined.MuiSelect-outlined': {
    backgroundColor: 'transparent',
  },
})

const StyledTextarea = styled(TextareaAutosize)({
  width: '100%',
  marginTop: '20px',
  borderRadius: '10px',
  border: '1px solid #B9AEAEFC',
  padding: '10px',
  '&::placeholder': {
    color: '#94A3B8', // Placeholder text color
    fontSize: '15px',
    fontWeight: 700
  },
});
// Customizable Area End
