import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import {switchLanguage, I18n} from "../../../components/src/languageJson/i18n";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
 
  token: string;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  phoneNumber:string;
  errorMessagePhone:string;
  otpModal:boolean;
  forgotOTPvalue:string;
  apiResponseMessage:string;
  borderColor:string;
  otpVerifyToken:string;
  confirmPasswordVisible:boolean;
  passwordVisible:boolean;
  confirmPassword:string;
  newPassword:string;
  errorColorshow:boolean;
  apiResponsesuccessMessage:string;
  errorMessageConfirm: string;
  focusedConfirm: boolean;
  errorMessagePassword: string;
  errorMsg: string;
  password:string;
  conditionsMet: {
    hasLowercase: boolean,
    hasUppercase: boolean,
    hasNumber: boolean,
    hasSpecialChar: boolean,
    isLongEnough: boolean,
    noSpaces:boolean
  },
  allPasswordField:boolean;
  currentLanguage:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  
  verifyPhoneOtpCallId:string="";
  requestChangePasswordCallId: any = "";
 
  otpToken: any;
  isChangePassword: boolean = false;
  currentLanguage!:string;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start


 

    this.state = {
      accountType: "sms",
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      phoneNumber:"",
      errorMessagePhone:"",
      otpModal:false,
      forgotOTPvalue:"",
      apiResponseMessage:"",
      borderColor:"#CBD5E1",
      otpVerifyToken:"",
      confirmPasswordVisible:false,
      passwordVisible:false,
      confirmPassword:"",
      newPassword:"",
      errorColorshow:false,
      apiResponsesuccessMessage:"",
      errorMessageConfirm:"",
      focusedConfirm:false,
      errorMessagePassword:"",
      errorMsg: "",
      password:"",
      conditionsMet: {
        hasLowercase: false,
        hasUppercase: false,
        hasNumber: false,
        hasSpecialChar: false,
        isLongEnough: false,
        noSpaces:false
      },
      allPasswordField:false,
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',

    };
    this.handleAllValidation = this.handleAllValidation.bind(this);
    this.handleSetNewPassword = this.handleSetNewPassword.bind(this);
    // Customizable Area End
  }


  // Customizable Area Start



  async receive(from: string, message: Message) {
    if(getName(MessageEnum.NavigationPayLoadMessage) === message.id) {

        this.handleNavigationPayload(message)
      }
 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

     

        if (apiRequestCallId === this.requestChangePasswordCallId) {
          if (responseJson.message) {
           this.setState({
            apiResponsesuccessMessage:responseJson.message,
            errorColorshow:false,
            apiResponseMessage:""
           })
           this.NavigateToSuccessConfirm();

          } else if(responseJson.errors) {
            //Check Error 
            
            responseJson.errors.forEach((element: string) => {
              if (element === "Token and new password are required") {
                this.setState({
                  errorColorshow: true,
                  apiResponsesuccessMessage: "",
                  apiResponseMessage: this.tokenError(this.state.currentLanguage),
                });
              }
            });            

            // this.setState({apiResponseMessage:responseJson.errors[0], errorColorshow:true,apiResponsesuccessMessage:""})

          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
    }
  }

  tokenError = (languageCh: string): string => {
    return languageCh === "ar"
      ? "الرمز المميز وكلمة المرور الجديدة مطلوبة"
      : "Token and new password are required";
  };

  NavigateToSuccessConfirm = () => {
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SuccessConfirm"
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMessage)
  }


  handleShowConfirmPassword=()=>{
    this.setState({confirmPasswordVisible:!this.state.confirmPasswordVisible})
  }

  handleShowPassword=()=>{
    this.setState({passwordVisible:!this.state.passwordVisible})
  }

  handleNewPassword=(value:string)=>{
    const NospaceValue = value?.replace(/\s/g, '');
    this.setState({newPassword:NospaceValue})
  }

  handleConfirmPassword=(value:string)=>{
    const NospaceValue = value?.replace(/\s/g, '');
    this.setState({confirmPassword:NospaceValue})
  }

handleSetNewPassword = async () => {
  this.handleAllValidation();
    const data = 
    {
      "data": {
          new_password:this.state.newPassword,
          password_confirmation:this.state.confirmPassword,
          token :this.state.token
      }}
    await this.SetNewPasswordAPIcall({
        ContentType: 'application/json',
        methods: 'PUT',
        endPoint: 'bx_block_forgot_password/passwords/update',
  
        Body: data,
    })
}
SetNewPasswordAPIcall = async (data: {ContentType:string,methods:string,endPoint:string,Body:{data:{
    new_password:string, password_confirmation:string,
  token:string
}}}) => {
  const { ContentType, methods, endPoint, Body } = data;
  const Header = {
      "Content-Type": ContentType,
  };
  const SignInAcoount = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.requestChangePasswordCallId = SignInAcoount.messageId
  SignInAcoount.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
  );
  SignInAcoount.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );
  SignInAcoount.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
  );
  Body &&
      SignInAcoount.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),

          JSON.stringify(Body)
      );
  runEngine.sendMessage(SignInAcoount.id, SignInAcoount);
  return SignInAcoount.messageId;
};

handleNavigationPayload = (message: Message) => {

    const data = message.getData(
      getName(MessageEnum.InfoPageBodyMessage)
    );
    const parsedData = data ;
    this.setState({token:parsedData})

  }


  NavigatetoForgotPassword=()=>{
    const navigateMessage: Message = new Message(
        getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "ForgotPassword"
    );
    navigateMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(navigateMessage)

}

handleAllValidation = async () => {
  const {  newPassword, confirmPassword } = this.state;
  if ( !newPassword || !confirmPassword ) {
    this.setState({ errorMsg: `${I18n.t("fillallfields")}`  });
  }
  if(!this.getErrorMessage()){
      const requestBody ={
        ContentType: 'application/json',
        methods: 'PUT', 
        endPoint: 'bx_block_forgot_password/passwords/update', 
        Body:{
          "data": {
              "new_password": newPassword,
              "password_confirmation": confirmPassword,
              "token": this.state.token
          }
        }
      }
  this.SetNewPasswordAPIcall(requestBody)
  }
};

getErrorMessage = () => {
  return (
    this.state.errorMessagePassword ||
    this.state.errorMessageConfirm ||
    this.state.errorMsg
  );
};

handlePassword = (value: string) => {
  if (value === "") {
    this.setState({
      password: value,
      conditionsMet: {
        hasUppercase: false,
        hasLowercase: false,
        hasSpecialChar: false,
        hasNumber: false,
        noSpaces: false,
        isLongEnough: false,
      },
    allPasswordField: false,
    errorMessagePassword: "",
    errorMessageConfirm: "",
    });
  }
  
  const conditionsMet = {
    hasUppercase: /[A-Z]/.test(value),
    hasLowercase: /[a-z]/.test(value),
    isLongEnough: value?.length >= 8,
    hasNumber: /\d/.test(value),
    noSpaces: !/\s/.test(value),
    hasSpecialChar: /[\W_]/.test(value),
  };

  const allConditionsMet = Object.values(conditionsMet).every(Boolean);
  this.setState({
    newPassword: value,
    conditionsMet,
    allPasswordField: allConditionsMet,
    errorMsg:'',
    errorMessagePassword: allConditionsMet ? "" : `${I18n.t("allPasswordErr")}`,
  },()=>{
    this.handlePasswordConfirm(this.state.confirmPassword);
  });
  if(value === this.state.confirmPassword || value===""){
    this.setState({
      errorMessagePassword: "",
    })
  }
};

  handlePasswordConfirm = (value: string) => {
    if (value.length > 0) {
      if (value === this.state.password || value === "") {
        this.setState({ confirmPassword: value, errorMessageConfirm: "", errorMsg: '' }, () => {
        });
      } else if (value !== this.state.password) {
        this.setState({
          confirmPassword: value,
          errorMessageConfirm: `${I18n.t("errorMessageConfirm")}`,
        });
      }
    }
    else if (!this.state.focusedConfirm && !value) {
      this.setState({
        confirmPassword: value,
        errorMessageConfirm: "",
      });
    } else {
      this.setState({
        confirmPassword: value,
        errorMessageConfirm: this.state.confirmPassword ? "" : `${I18n.t("errorMessageConfirm")}`,
      });
    }
  }

getCheckStatus(item:any ,checkedCheck:any, unChecked:any) {
  return item.isMet ? checkedCheck : unChecked;
}

getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
  return this.state.currentLanguage === "ar" ? firstValue : secondValue;
};

switchToEnglish = () => {
  switchLanguage("en");
};

switchToArabic = () => {
  switchLanguage("ar");
};

  // Customizable Area End
}
