import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import { getStorageData } from ".../../../packages/framework/src/Utilities";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { Typography } from "@material-ui/core";

export interface Dropdown {
  label: string;
  value: string;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  isFieldValueChanged: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  errorMessagePhoneNumber:string;
  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  branches:any;
  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;
  errors:any;
  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  uplodaProfile:React.RefObject<HTMLInputElement>;
  uploadImg:File |null;
  uploadCover:React.RefObject<HTMLInputElement>;
  coverImg:File |null;
  NationalId:string;
  VatRegNo:string;
  NationalAddress:string;
  BusinessName:string;
  profileData:any;
  businessDescription:string;
  fileInput1:React.RefObject<HTMLInputElement>
  fileInput2:React.RefObject<HTMLInputElement>
  fileInput3:React.RefObject<HTMLInputElement>
  fileInput4:React.RefObject<HTMLInputElement>
  fileInput:React.RefObject<HTMLInputElement>
  otherFileInput:React.RefObject<HTMLInputElement>
  authorizedIDcardFile:null |File,
      authorizedCertificateFile:null |File,
      TradingLicenseFile:null |File,
      VATtaxidentifireFile:null |File,
      ibanCertificateFile:null |File,
      InputFileOther:null |File,
      categoryData:any,
      industryCat:string,
      selectedCategory:any,
      showMOdaltime:boolean;
      modalBranchIndex: any,
  branchNames:any;
  branchLocations:any;
  branchContactNos:any;
  branchOperatingHoursNew:any;
  branchSelection:string;
  otherpdfFile:File | null;
  loading:boolean;
  branchName:string;
  branchCode:any;
  branchLOcation:string;
  branchContactNo:string;
  startTimeam:string;
  endTimeam:string;
  successError:boolean;
  dialogOpen:boolean;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userProfileUpdateApiCallId: string="";
  getCategoriesApiCallId:string=""
  updateCoveApiCalId:string=""
  userAttr: any;
  branches:any;
  errors:any;
  branchCode:any;
  editUserApiCallId: string = "";
  postBranchAPIcallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      errors:{},
      isFieldValueChanged: {
        TradingLicense: false,
        VATtaxidentifire: false,
        authorizedCertificate: false,
        authorizedIDcard: false,
        ibanCertificate: false,
      },
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      errorMessagePhoneNumber:"",
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      branchContactNo:"",
      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      uplodaProfile: React.createRef(),
      uploadCover:React.createRef(),
      fileInput1: React.createRef(),
      fileInput2: React.createRef(),
      fileInput3: React.createRef(),
      fileInput4: React.createRef(),
      fileInput:React.createRef(),
      otherFileInput:React.createRef(),
      authorizedIDcardFile:null,
      authorizedCertificateFile:null,
      TradingLicenseFile:null,
      VATtaxidentifireFile:null,
      ibanCertificateFile:null,
      InputFileOther:null,
      modalBranchIndex: null,
      uploadImg:null,
      coverImg:null,
      NationalId:'',
      industryCat:"",
  VatRegNo:'',
  NationalAddress:'',
  BusinessName:'',
  businessDescription:"",
  categoryData:[],
  selectedCategory:"",
  showMOdaltime:false,
  profileData:{
    "id": "",
    "type": "",
    "attributes": {
        "business_name": "",
        "business_description": "",
        "category": "",
        "support_email": "",
        "address": "",
        "vat_registration_number": "",
        "national_id": "",
        "photo_image": {url:"",name:""},
        "banner_image_image": {url:"",name:""},
        "trading_license_image": {url:"",name:""},
        "vat_tax_identifier_image": {url:"",name:""},
        "authorization_certificate_image": {url:"",name:""},
        "authorized_id_card_image": {url:"",name:""},
        "iban_certificate_image": {url:"",name:""},
        "other_document_image": {url:"",name:""},
        "branches": [
            {
                "id": "",
                "branch_name": "",
                "branch_code": "",
                "branchOperatingHours": "",
                "location_link": "",
                "mobile_number": "",
                "startTime":{hours:"",minutes:"",period:"AM"},
                "endTime":{hours:"",minutes:"",period:"AM"},
            }
        ]
    }
    },
  branches: [
      {
        id: Date.now(),
        branchName: "",
        branchCode:"",
        branchLocation: "",
        branchContactNo: "",
        branchOperatingHours: "",
        selectedDays: [],
        startTime:{hours:"",minutes:"",period:"AM"},
        endTime:{hours:"",minutes:"",period:"AM"},
      },
    ],
    branchNames: [{ id: 1, value: "" }],
    branchLocations:[{ id: 1, value: "" }],
    branchContactNos: [{ id: 1, value: "" }],
    branchOperatingHoursNew: [{ id: 1, value: "" }],
    branchSelection:"",
    otherpdfFile:null,
    loading:false,
    branchName:"",
    branchCode:"",
    branchLOcation:"",
    startTimeam:"AM",
    endTimeam:"AM",
    successError:false,
    dialogOpen:false,

};

    this.arrayholder = [];

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }


   
  async componentDidMount() {
    // Customizable Area Start
  super.componentDidMount();
  let tokenvalue = await getStorageData('token')
  this.getUserProfile(tokenvalue)
  this.GetCategory()
  
// Customizable Area End

}
  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.userProfileGetApiCallId) {
        this.handleUserProfileGetApi(responseJson);
      }
      if (apiRequestCallId === this.userProfileUpdateApiCallId) {
        this.handleUserProfileUpdateApi(responseJson);
      }

      if (apiRequestCallId == this.getCategoriesApiCallId) {
        this.handleGetCategoriesApi(responseJson);
      }

  }

    
    // Customizable Area End
  }

   // Customizable Area Start

   getErrorMessageNonFormik = (value: string) => {
    return (

      <Typography
        style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
      >
        {value}
      </Typography>

    );
  };  

  findCategory(responseJson: any, categoryData: any) {
    if (!categoryData) {
      return 'undef';
    }
  
    const selectedCategoryName = responseJson?.data?.attributes?.category;
    if (!selectedCategoryName) {
      return 'undef';
    }
  
    const selectedCategory = categoryData.find(
      (category: any) => category?.attributes?.name === selectedCategoryName
    );
  
    return selectedCategory || 'undef';
  }  

   handleUserProfileGetApi(responseJson: any) {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ profileData: responseJson.data });
      const selectedCategory = this.findCategory(responseJson, this.state.categoryData)      
      this.setState({
        selectedCategory
      })
      const selectedBranch = responseJson.data.attributes.branch_selection; 
      this.setState({ branchSelection: selectedBranch });
    }
    this.setState({isFieldValueChanged: {
      TradingLicense: false,
      VATtaxidentifire: false,
      authorizedCertificate: false,
      authorizedIDcard: false,
      ibanCertificate: false,
    },})
  }
  
  handleUserProfileUpdateApi(responseJson: any) {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ profileData: responseJson.data });
    } else {
      this.setState({
        successError: responseJson.errors,dialogOpen: true
      });
    }
  }
  
  handleGetCategoriesApi(responseJson: any) {    
    if (responseJson && responseJson.data) {
      this.setState({ categoryData: responseJson.data });
      
      const initialValue = responseJson.data.find((option: any) => 
        option?.attributes?.name.trim().toLowerCase() === "electronics"
      );
  
      this.setState({ selectedCategory: initialValue });
    }
  }
  

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  GetCategory = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserProfile = async (tokenvalue: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: tokenvalue,
      // token:"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NTA4LCJleHAiOjE3MzExMzAwNjAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.6BCI1vxk788QQLTc_SP7xaa0aSSu4ErXMdU-EmtsbQ6XtZJsB9kjqwtzDF3z_el9fly1QCPoIatN_pln4xwcdw"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileGetApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profile/show"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

updateUserProfile = () => {

    const header = {
      token: localStorage.getItem("token"),
    };

    const formData:any = new FormData();
    formData.append("profile[photo]", this.state.uploadImg);
    formData.append("profile[business_name]", this.state.profileData.attributes.business_name);
    formData.append("profile[business_description]", this.state.profileData.attributes.businessDescription);
    formData.append("profile[category]", Number(this.state.industryCat));
    this.state.TradingLicenseFile && formData.append("profile[trading_license]", this.state.TradingLicenseFile, this.state.TradingLicenseFile.name);
    this.state.VATtaxidentifireFile && formData.append("profile[vat_tax_identifier]",  this.state.VATtaxidentifireFile, this.state.VATtaxidentifireFile?.name);
    this.state.authorizedCertificateFile && formData.append("profile[authorization_certificate]", this.state.authorizedCertificateFile, this.state.authorizedCertificateFile?.name);
    this.state.authorizedIDcardFile && formData.append("profile[authorized_id_card]", this.state.authorizedIDcardFile, this.state.authorizedIDcardFile?.name);
    this.state.ibanCertificateFile && formData.append("profile[iban_certificate]",  this.state.ibanCertificateFile, this.state.ibanCertificateFile?.name);
    formData.append("profile[address]", this.state.profileData.attributes.address);
    formData.append("profile[support_email]", this.state.profileData.attributes.support_email);
    formData.append("profile[national_id]", this.state.profileData.attributes.national_id);
    formData.append("profile[vat_registration_number]", this.state.profileData.attributes.vat_registration_number);
    this.state.InputFileOther && formData.append("profile[other_document]",  this.state.InputFileOther);
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileUpdateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profile/update_merchant"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 

  updateCoverPhoto = () => {
    

    const header = {
      token: localStorage.getItem("token"),
    };

    const formData:any = new FormData();
    formData.append("profile[banner_image]", this.state.coverImg);
   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCoveApiCalId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profile/update_cover_image"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleFileChangeProfile4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
    this.setState({uploadImg:file})
  }
  };

  
  handleFileProfileick4 = () => {
    this.state.uplodaProfile.current?.click();
  };

  handleFileCoverPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
    this.setState({coverImg:file},()=>this.updateCoverPhoto())
    }
  };

  
  handleFileCoverClick = () => {
    this.state.uploadCover.current?.click();
  };
  onValueChange = (name: string, value: string |null) => {
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      profileData: {
        ...prevState.profileData,
        attributes: {
          ...prevState.profileData.attributes,
          [name]: value,
        },
      },
    }));

  }

  onValueChangeNew = (index: number, field: string, event: any) => {
    const branches = [...this.state.profileData.attributes.branches];
    branches[index][field] = event.target?.value;
    this.setState({ branches });
  };


onEditTask = () => {
  this.setState({ loading: true });
  const header = {
    token: localStorage.getItem("tokenCustom"),
  };
  
  const formData:any = new FormData();
  formData.append("profile[business_name]", this.state.BusinessName);
  formData.append("profile[business_description]", this.state.businessDescription);
  formData.append("profile[category]", this.state.industryCat);
  formData.append("profile[trading_license]", this.state.TradingLicenseFile);
  formData.append("profile[vat_tax_identifier]", this.state.VATtaxidentifireFile);
  formData.append("profile[authorization_certificate]", this.state.authorizedCertificateFile);
  formData.append("profile[authorized_id_card]", this.state.authorizedIDcardFile);
  formData.append("profile[iban_certificate]", this.state.ibanCertificateFile);
  formData.append("profile[address]", this.state.NationalAddress);
  formData.append("profile[support_email]", this.state.email);
  formData.append("profile[national_id]", this.state.NationalId);
  formData.append("profile[vat_registration_number]", this.state.VatRegNo);
  formData.append("profile[other_document]", this.state.otherpdfFile);

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.editUserApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.editUserApiEndPoint}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.editUserApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

onPostBranch = () => {  
  const header = {
    token: localStorage.getItem("tokenCustom"),
    "Content-Type": "application/json"
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const data = this.state.branches.map((item: any) => ({
    branch_name: item.branchName,
    branch_location: item.branchLocation,
    branch_contact_number: item.branchContactNo,
    branch_operating_hours: item.branchOperatingHours,
    branch_code: "12345",
    created_at: "2024-10-23T07:49:08.610Z"
  }));

  const dataSend = { branches: data };

  this.postBranchAPIcallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(dataSend)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_profile/profile/branches`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

FormuserSchema = () => {
  return Yup.object().shape({
    NationalId: Yup.string().matches(/^\d+$/, "*Please enter a valid National ID number.")
    .required("*Please enter a valid National ID number.").nullable(),
    VatRegNo: Yup.string().required("*Please enter a valid VAT Registration number."),
    NationalAddress: Yup.string().required("Please enter a valid National Address"),
    BusinessName: Yup.string().required("*Please enter a valid business name"),
    industryCat:Yup.string().required("*Please select your Industry category ."),
    branchSelection:Yup.string().required("*Please choose eithr Individual Branch or Multiple Branches."),
   
    businessDescription:Yup.string().required("*Please provide brief Business Description"),
    TradingLicense: Yup.mixed()
    .required("*Please upload valid Trading License (up to 5mb)")
    .test('fileSize', '*Please upload valid Trading License (up to 5mb)', value => {
      return value && value.size >= 200000000; 
    })
    .nullable(),
    VATtaxidentifire: Yup.mixed()
    .required("*Please upload VAT Tax Identifier (up to 5mb)")
    .test('fileSize', '*Please upload VAT Tax Identifier (up to 5mb)"', value => {
      return value && value.size <= 200000000; 
    })
    .nullable(),
    authorizedCertificate: Yup.mixed()
    .required("*Please upload the Authorization Certificate (up to 5mb)")
    .test('fileSize', '*Please upload the Authorization Certificate (up to 5mb)', value => {
      return value && value.size <= 200000000; 
    })
    .nullable(),
    authorizedIDcard: Yup.mixed()
    .required("*Please upload Authorized ID Card (up to 5mb)")
    .test('fileSize', '*Please upload Authorized ID Card (up to 5mb)', value => {
      return value && value.size <= 200000000; 
    })
    .nullable(),
    ibanCertificate: Yup.mixed()
    .required("*Please upload the IBAN certificate (up to 5mb)")
    .test('fileSize', '*Please upload the IBAN certificate (up to 5mb)', value => {
      return value && value.size <= 200000000; 
    })
    .nullable(),
    branches: Yup.array().of(
      Yup.object().shape({
        branchName: Yup.string().required("*Please enter the Branch Name."),
        branchCode: Yup.string().required("*Please provide the Branch Code."),
        location_link: Yup.string().required("*Please provide the Branch Location."),
        branchContactNo: Yup.string()
          .test('is-valid-phone', '*Please enter a valid contact number for the branch.', function (value) {
            return isValidPhoneNumber(value || '');
          })
          .required('*Please enter a valid contact number for the branch.'),
        branchOperatingHours: Yup.string().required("*Please specify the Branch Operating Hours."),
        selectedDays: Yup.array().of(Yup.string()).required('*Please select at least one day.'),
       
      })
    ).min(1, "*At least one branch is required.")
  });
};

  validateForm = async () => {
    const schema = this.FormuserSchema();
    schema
      .validate({
        NationalId: this.state.profileData.attributes?.national_id, 
        VatRegNo: this.state.profileData.attributes?.vat_registration_number, 
        NationalAddress: this.state.profileData.attributes?.address,
        BusinessName: this.state.profileData.attributes?.business_name, 
        industryCat: this.state.industryCat, 
        businessDescription:this.state.profileData.attributes?.business_description,
        ibanCertificate: this.state.profileData.attributes?.iban_certificate_image, 
        authorizedIDcard: this.state.profileData.attributes?.authorized_id_card_image?.name, 
        VATtaxidentifire: this.state.profileData.attributes?.vat_tax_identifier_image,
        TradingLicense: this.state.profileData.attributes?.trading_license_image,
        authorizedCertificate: this.state.profileData.attributes?.authorization_certificate_image,
        branchSelection: this.state.branchSelection,
        branchName: this.state.profileData.attributes?.branches?.branch_name,
        branchCode:this.state.profileData.attributes?.branches?.branch_code,
        branchLOcation: this.state.profileData.attributes?.branches?.location_link, 
        branchContactNo: this.state.profileData.attributes?.branches?.mobile_number,
        branchOperatingHours: this.state.profileData.attributes.branches?.business_hours, 
        branches: this.state.profileData.attributes?.branches,
      }, { abortEarly: false })
      .then(() => {
        this.onPostBranch()
        this.onEditTask()

      })
      .catch((validationError) => {
        const newErrors: Record<string, string> = {};
        validationError.inner.forEach((error: any) => {
          newErrors[error.path] = error.message;
        });
        console.log("==>",newErrors, validationError);
        
        this.setState({
          errors: newErrors,
        });
      });
  };

handleSubmit = (e: any) => {
    e.preventDefault()
    this.validateForm();
    this.updateUserProfile();
    this.getUserProfile("tokenvalue");
    
  }

  handleNumberPhone = (e: any) => {
    if (e && isValidPhoneNumber(e.toString())) {
      this.setState({ phoneNumber: e, errorMessagePhoneNumber: "" });
    } else {
      this.setState({
        phoneNumber: e,
        errorMessagePhoneNumber: "Invalid Phone Number",
      });
    }
  };



  handleFileInputClick = () => {
    this.state.fileInput.current?.click();
  };
  handleFileInputClick1 = () => {
    this.state.fileInput1.current?.click();
  };
  handleFileInputClick2 = () => {
    this.state.fileInput2.current?.click();
  };
  handleFileInputClick3 = () => {
    this.state.fileInput3.current?.click();
  };
  handleFileInputClick4 = () => {
    this.state.fileInput4.current?.click();
  };
  handleOtherFileInputClick = () => {
    this.state.otherFileInput.current?.click();
  };
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
   
        this.setState(prevState => ({
            profileData: {
                ...prevState.profileData,
                attributes: {
                    ...prevState.profileData.attributes,
                    iban_certificate_image: file 
                }
            }

        }))
        this.setState({ibanCertificateFile:file})
        this.setState({isFieldValueChanged: {...this.state.isFieldValueChanged, ibanCertificate: true}})
}}

  OtherhandleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
        this.setState(prevState => ({
            profileData: {
                ...prevState.profileData,
                attributes: {
                    ...prevState.profileData.attributes,
                    other_document_image: file 
                }
            }
        }))
        this.setState({InputFileOther:file})
}
  };

  handleFileChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
   
        this.setState(prevState => ({
            profileData: {
                ...prevState.profileData,
                attributes: {
                    ...prevState.profileData.attributes,
                    authorized_id_card_image: file 
                }
            }
        }))
        this.setState({authorizedIDcardFile:file})
        this.setState({isFieldValueChanged: {...this.state.isFieldValueChanged, authorizedIDcard: true}})
}}

typeChange(status1:any) {
  switch (status1) {
    case 'success':
      return 'bg-success'; 
    case 'warning':
      return 'bg-warning'; 
    case 'error':
      return 'bg-error';
    default:
      return 'bg-default';
  }
}
  handleFileChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
   
        this.setState(prevState => ({
            profileData: {
                ...prevState.profileData,
                attributes: {
                    ...prevState.profileData.attributes,
                    authorization_certificate_image: file 
                }
            }
        }))
        this.setState({authorizedCertificateFile:file})
        this.setState({isFieldValueChanged: {...this.state.isFieldValueChanged, authorizedCertificate: true}})
}}

  handleFileChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
   
        this.setState(prevState => ({
            profileData: {
                ...prevState.profileData,
                attributes: {
                    ...prevState.profileData.attributes,
                    vat_tax_identifier_image: file 
                }
            }
        }))
        this.setState({VATtaxidentifireFile:file})
        this.setState({isFieldValueChanged: {...this.state.isFieldValueChanged, VATtaxidentifire: true}})
}}

  handleFileChange4 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if(file ){
   
        this.setState(prevState => ({
            profileData: {
                ...prevState.profileData,
                attributes: {
                    ...prevState.profileData.attributes,
                    trading_license_image: file 
                }
            }
        }))
        this.setState({TradingLicenseFile:file})
        this.setState({isFieldValueChanged: {...this.state.isFieldValueChanged, TradingLicense: true}})
}}

handleCategoryChange = (newValue: any) => {     
  
  if (newValue) {
    this.setState({ selectedCategory: newValue });
    this.setState({industryCat: newValue?.attributes?.id})
    this.setState({
      profileData: {
        ...this.state.profileData,
        attributes: {
          ...this.state.profileData.attributes,
          category: newValue.name,
        }
      }
    });
    this.onValueChange("category", newValue.id || '');
  } else {
    this.setState({ selectedCategory: null });
  }
};

  handleShowTime = (index: number) => {
    this.setState({ showMOdaltime: true, modalBranchIndex: index });
  };
  handleHideshowTime = () => {
    this.setState({ showMOdaltime: false, modalBranchIndex: null });
  };


  addBranch = () => {    
    const newBranch = {
      id: Date.now(),
      branchName: "",
      branchCode:"",
      branchLocation: "",
      branchContactNo: "",
      branchOperatingHours: "",
      selectedDays: [],
      startTime:{hours:"",minutes:"",period:"AM"},
      endTime:{hours:"",minutes:"",period:"AM"},
    };   

    this.setState(prevState => ({
      profileData: {
          ...prevState.profileData,
          attributes: {
              ...prevState.profileData.attributes,
              branches:[...prevState.profileData.attributes.branches,newBranch]
          }
      }

  }))

  };

  deleteBranch = (delbtnindex: number) => {   
    const getBranches = this.state.profileData.attributes.branches.filter((_:any, index:any)=>(
      index !== delbtnindex
    ))
    this.setState({profileData:{...this.state.profileData,attributes:{...this.state.profileData.attributes,branches:getBranches}}})
    
  };

  handlePeriodChange(event:any, branchId:any) {
    const updatedHour = event.target?.value;
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches?.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              startTime: {
                ...branch?.startTime,
                period: updatedHour,
              },
            }
          : branch
      )}},
    }));
  }
  handleMinuteChange (event:any, branchId:any) {
    const updatedHour = event.target?.value;
    
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches
        .map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              startTime: {
                ...branch?.startTime,
                minutes: updatedHour,
              },
            }
          : branch
      )}},
    }));
  }

  handleHourChange (event:any, branchId:any) {
    const updatedHour = event.target.value;
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              startTime: {
                ...branch?.startTime,
                hours: updatedHour,
              },
            }
          : branch
      )}},
    }));
  }


  handlePeriodChangeEnd(event:any, branchId:any) {
    const updatedHour = event.target.value;
    this.setState((prevState) => ({
        profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches.map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              endTime: {
                ...branch?.endTime,
                period: updatedHour,
              },
            }
          : branch
      )}},
    }));
  }
  handleMinuteChangeEnd (event:any, branchId:any) {
    const updatedHour = event.target.value;
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches
        .map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              endTime: {
                ...branch?.endTime,
                minutes: updatedHour,
              },
            }
          : branch
      )}},
    }));
  }

  handleHourChangeEnd (event:any, branchId:any) {
    const updatedHour = event.target.value;
    this.setState((prevState) => ({
      profileData: {...prevState.profileData,attributes:{...prevState.profileData.attributes,branches:prevState.profileData.attributes.branches
        .map((branch:any,index:any) =>
        index === branchId
          ? {
              ...branch,
              endTime: {
                ...branch?.endTime,
                hours: updatedHour,
              },
            }
          : branch
      )}},
    }));
  }

  onHandleSubmitTime = () => {
    const { branches } = this.state.profileData.attributes;
    const {modalBranchIndex} = this.state;
    if (modalBranchIndex !== null) {

      const start = `${branches[modalBranchIndex].startTime.hours}:${branches[modalBranchIndex].startTime.minutes} ${branches[modalBranchIndex].startTime.period}`;
      const end =`${branches[modalBranchIndex].endTime.hours}:${branches[modalBranchIndex].endTime.minutes} ${branches[modalBranchIndex].endTime.period}`;

      branches[modalBranchIndex].branchOperatingHours = `From ${start} To ${end}`;
      this.setState({ branches, showMOdaltime: false });
    }
  };

  handleCheckboxChange = (index:number, day:string, isChecked:any) => {

    this.setState((prevState) => {
      const updatedBranches = [...prevState.branches];
      let branch = updatedBranches[index];
  
      if (branch) {
        branch.selectedDays = branch.selectedDays || [];
  
        if (isChecked) {
          branch.selectedDays = [...branch.selectedDays, day];
        } else {
          branch.selectedDays = branch.selectedDays.filter((selectedDay: string) => selectedDay !== day);
        }
  
        updatedBranches[index] = branch;
      }
  
      return { branches: updatedBranches };
    });
  };

  branchValueReset=()=>{
    if(this.state.branchSelection=="0")
      {
        this.setState({branches:  [
          {
            id: Date.now(),
            branchName: "",
            branchLocation: "",
            branchContactNo: "",
            branchOperatingHours: "",
            selectedDays: [],
            startTime:{hours:"",minutes:"",period:"AM"},
            endTime:{hours:"",minutes:"",period:"AM"},
          },
        ],})
      }
  }

  navigateEducationalUserProfile = () => {
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EducationalUserProfile"
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMessage)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.branchSelection !=this.state.branchSelection){
      this.branchValueReset()
    };
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen:false,
      successError: false,
    })
  }

  nationalError(field: string) {
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  vatRegNo (field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  nationalAddress(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  businessName(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  tradingLicense(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  vattaxidentifire(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  authorizedCertificate(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  authorizedIDcard(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  ibanCertificate(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  industryCat(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  businessDescription(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  branchSelection(field:string){
    return this.state.errors[field] ? 
    this.getErrorMessageNonFormik(this.state.errors[field]) : null;
  }

  branchNameError(fieldPath: string) {
    const error = this.state.errors[fieldPath];    
    return error ? this.getErrorMessageNonFormik(error) : null;
  }

  branchCodeError(fieldPath: string) {
    const error = this.state.errors[fieldPath];
    return error ? this.getErrorMessageNonFormik(error) : null;
  }

  branchLocationLinkError(fieldPath: string) {
    const error = this.state.errors[fieldPath];
    return error ? this.getErrorMessageNonFormik(error) : null;
  }

  branchContanctNoError(fieldPath: string) {
    const error = this.state.errors[fieldPath];
    return error ? this.getErrorMessageNonFormik(error) : null;
  }

  branchOperatingHoursError(fieldPath: string) {
    const error = this.state.errors[fieldPath];
    return error ? this.getErrorMessageNonFormik(error) : null;
  }

  getCoverImageSrc() {
    return this.state.coverImg != null
      ? URL.createObjectURL(this.state.coverImg)
      : this.state.profileData.attributes?.banner_image_image?.url;
  }


  getUploadImageSrc() {
    return this.state.uploadImg != null
      ? URL.createObjectURL(this.state.uploadImg)
      : this.state.profileData.attributes?.photo_image?.url;
  }

  handleBranchSelectionChange(newValue: any) {
    this.onValueChange("branchSelection", newValue ? newValue.value : null);
    this.setState({ branchSelection: newValue ? newValue.value : null });

  }


  handleStatusChangeD(status:any) {
    switch (status) {
      case 'success':
        return 'bg-success'; 
      case 'warning':
        return 'bg-warning'; 
      case 'error':
        return 'bg-error'; 
      case 'green':
        return "go";
      case 'orange':
        return "stay";
      default:
        return 'bg-default';
    }
  }
   
  // Customizable Area End

}
